import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import { Box, Divider, Typography } from '@material-ui/core';
import { gridFilteredRowsLookupSelector, useGridApiRef} from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { getCuotasMesPendientePasado } from '../../../../services/cuotas';
import DataGridInnovattio from '../../../../components/DataGridInnovatiio';
import { columnasCuotas } from './ColumnasCuotas';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '20px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function CuotasAtrasadasPendientesContent() {
    const classes = useStyles();

    const [cuotasMesActualPendientePasado, setCuotasMesActualPendientePasado] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const apiRef = useGridApiRef();

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando cuotas")

        const usuario_id = usuarioSeleccionado.id

        getCuotasMesPendientePasado({ 
          usuario_id: usuario_id,
        }).then( (response) => {
          response && setCuotasMesActualPendientePasado(response.cuotas)
          setLoading(false)
        })

    }, [])
    
    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */
    

    const handleAlumnoDetalle = (row) => {
      history.push('alumnos/detalle/' + row.alumno_id )
    }
    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    
    const CustomFooterTotalComponent = (props) => {
      const euroFormat = Intl.NumberFormat("es-ES")
      return (
        <Box sx={{ paddingX: "10px", paddingY: "20px", display: "flex", backgroundColor: '#f0f0f0' }}><b> [{props.numCuotas} registros] {props.title} : {euroFormat.format(props.total)}€</b></Box>
      );
    }
    const Contenido = () => {
      const [numCuotas, setNumCuotas] = useState(0)
      const [importeCuotas, setImporteCuotas] = useState(0)

      React.useEffect(() => {
        apiRef.current.subscribeEvent("stateChange", () => {
          const filtrado = gridFilteredRowsLookupSelector(apiRef)

          console.log(filtrado)
          const res = cuotasMesActualPendientePasado.filter((item) => Object.keys(filtrado).includes(item.id));
            const total = res
                      .map((item) => item.importe)
                      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
          setNumCuotas(Object.keys(filtrado).length)
          setImporteCuotas(total)
        });
      }, [apiRef]);

      return (
        <>
          <div style={{ padding: "0 16px" }}>
              <DataGridInnovattio 
                apiRef={apiRef}
                  rows={cuotasMesActualPendientePasado} 
                  columns={columnasCuotas} 
                  rowHeight={30}
                  onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.row) }
                  customFooter={ CustomFooterTotalComponent }
                  componentsProps={{
                    footer: { 
                      title: 'Total pendiente',
                      total: importeCuotas,
                      numCuotas: numCuotas 
                    }
                  }}
              />
          </div>
        </>
              
      )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
