import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AlumnosRegistradosContent from './components/AlumnosRegistradosContent';
import AlumnosRegistradosMarketingContent from './components/AlumnosRegistradosMarketingContent';

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";
import { useSelector } from 'react-redux';

const AlumnosRegistradosListado = () => {
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    return (
        <>

            <TemplateDashboard>
                { 
                    usuarioSeleccionado.perfil_id === '7' ?
                    <AlumnosRegistradosMarketingContent />
                    :
                    <AlumnosRegistradosContent />
                }
            </TemplateDashboard>

        </>
    )
};

export default AlumnosRegistradosListado;