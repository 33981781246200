import { Tooltip } from "@material-ui/core";

export const columnasCuotas = [
    { field: 'id', hide: true, headerName: 'ID' },
    { field: 'alumno_nombre', headerName: 'Alumno', flex: 1 },
    { field: 'comercial_nombre', headerName: 'Comercial', flex: 1 },
    { field: 'fecha', headerName: 'Fecha', flex: 1 },
    { field: 'importe', headerName: 'Importe', flex: 1 },
    { field: 'producto_nombre', headerName: 'Producto Nombre', minWidth: 150, flex: 1 },
    { field: 'num_cuotas', headerName: 'Num cuotas', minWidth: 150, flex: 1 },
    // { field: 'fecha_baja', headerName: 'Fecha baja', minWidth: 150, flex: 1 },
    { 
      field: 'es_cuota_inicial', 
      headerName: 'Es primera cuota', 
      minWidth: 150, 
      renderCell: (cellValues) => {
        return (
          <>{cellValues.row.es_cuota_inicial === "1" ? "SI" : "NO"}</>
        )
      }
    },{ 
      field: 'es_pago_referencia', 
      headerName: 'Pago recurrente', 
      minWidth: 150, 
      renderCell: (cellValues) => {
        return (
          <>{cellValues.row.es_pago_referencia === "1" ? "SI" : "NO"}</>
        )
      }
    },
    
    {
      'field': 'estado_nombre', 
      'width':150, 
      'headerName': 'Estado', 
      'description': 'Estado',
      renderCell: (cellValues) => {
          return (
            cellValues.row.codigo_cobro_descripcion !== "" && cellValues.row.codigo_cobro_descripcion !== null ? 

            <Tooltip title={cellValues.row.codigo_cobro_descripcion} >
              <span className="table-cell-trucate">{cellValues.row.estado_nombre}</span>
            </Tooltip>
            
            :
            
            <span className="table-cell-trucate">{cellValues.row.estado_nombre}</span>

          );
      } 
    },
    
    { field: 'codigo_cobro', headerName: 'Codigo cobro', minWidth: 150, flex: 1 },

];