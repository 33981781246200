import { PRIMARY_COLOR } from "../../constants/colores"
import noResult from '../../assets/img/no-result.png'
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    emptyContainer: {
        minHeight: "80vh",
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
        display: "flex",
        alignContent: "center",
        alignItems: "center"
    },
    emptyImage: {
        maxWidth: '100%'
    }
}));
const EmptyComponent = ({mensaje = "No hay datos!!"}) => {
    const classes = useStyles();

    return (
        <Box className={classes.emptyContainer}>
            <img className={classes.emptyImage} src={noResult} alt="No se han encontrado resultados" />
            <h1 style={{ color: PRIMARY_COLOR }}>{mensaje}</h1>
        </Box>
    )
}
export default EmptyComponent