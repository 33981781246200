import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'

/* -------------------------------------------------------------------------- */
/*                               Get Productos                                */
/* -------------------------------------------------------------------------- */
export async function getProductos({ usuario_id }) {
    try {
        var config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'text/json',
                'Authorization': "04871209347109238740"
            }
        };

        const response = await axios.get(BASE_URL_SERVICE + 'productos', {
            params: {
                usuario_id,
            }
        }, config);

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
