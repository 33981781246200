import React, { useEffect, useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Button, Chip, Dialog, DialogContent, DialogTitle, Grid, ListSubheader } from '@material-ui/core'



import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SyncIcon from '@material-ui/icons/Sync';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import SendIcon from '@material-ui/icons/Send';
import ViewListIcon from '@material-ui/icons/ViewList';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SearchIcon from '@mui/icons-material/Search';

import avatar from '../../assets/img/avatar.png';

import {
    selectUsuarioSeleccionado,
} from "../../redux/reducer/loginReducer";

import {
    Link,
} from 'react-router-dom';

import { PRIMARY_COLOR } from '../../constants/colores'
import { useHistory } from "react-router-dom";
import { getAvisosUsuario } from '../../services/alumnos';
import DialogModalSearch from './DialogModalSearch';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appbar: {
        backgroundColor: PRIMARY_COLOR,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        textAlign: 'center',
        lineHeight: '50px'
    },
    avatar: {
        width: 250,
    },
    list: {
        width: 250,
        marginTop: 10
    },
    fullList: {
        width: 'auto',
    },
}));


const NavbarTop = (props) => {
    const { title = null, showBack } = props

    const classes = useStyles();

    const [avisos, setAvisos] = useState([]);

    // --------------------------------------------------------------
    // handleAbrirSidebar
    //
    // Permite abrir y cerrar la sidebar
    // --------------------------------------------------------------
    // const dispatch = useDispatch();

    // --------------------------------------------------------------
    // Usuario seleccionado
    // --------------------------------------------------------------
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    // const preventDefault = (event) => event.preventDefault();

    useEffect(() => {

        const usuario_id = usuarioSeleccionado.id

        getAvisosUsuario({usuario_id}).then ( (response) => {
            response && setAvisos(response.avisos)
            // console.log(response)
        })

    }, [])



    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const anchor = 'left';

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <ListItem button component={Link} to="/home" >
                <ListItemIcon><DashboardIcon /></ListItemIcon>
                <ListItemText primary="Panel de control" />
            </ListItem>

            { avisos.length > 0 && 
            <ListItem button component={Link} to="/avisos" >
                <ListItemIcon><PeopleIcon /></ListItemIcon>
                <ListItemText primary="Avisos" />
                <Chip color="primary" size="small" label={avisos.length} />
    
            </ListItem>
            }
            
            <Divider />
            

            {/* Administrador: perfil_id = 1 */}

            { 
                (usuarioSeleccionado.perfil_id === "1" ) && 
                <>
                <ListSubheader>ALUMNOS</ListSubheader> 

                <ListItem button component={Link} to="/alumnos" >
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText primary="CRM" />
                </ListItem>
                
                {/* <ListItem button component={Link} to="/buscar_alumno" >
                    <ListItemIcon><ManageSearchIcon /></ListItemIcon>
                    <ListItemText primary="Buscar alumno" />
                </ListItem> */}

                <ListItem button component={Link} to="/alumnos_asignacion_masiva" >
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText primary="Asignación masiva" />
                </ListItem>

                <Divider />

                <ListSubheader>ADMINISTRACIÓN</ListSubheader>

                <ListItem button component={Link} to="/alumnos_registrados" >
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText primary="Alumnos Registrados" />
                </ListItem>

                <ListItem button component={Link} to="/accesos_tutea" >
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText primary="Accesos Tutea" />
                </ListItem>

                <ListItem button component={Link} to="/alumnos_baja" >
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText primary="Alumnos Baja" />
                </ListItem>

                <ListItem button component={Link} to="/facturas" >
                    <ListItemIcon><PictureAsPdfIcon /></ListItemIcon>
                    <ListItemText primary="Facturas" />
                </ListItem>

                <ListItem button component={Link} to="/cuotas" >
                    <ListItemIcon><ViewListIcon /></ListItemIcon>
                    <ListItemText primary="Previsión de cuotas" />
                </ListItem>

                <ListItem button component={Link} to="/analitica" >
                    <ListItemIcon><ViewListIcon /></ListItemIcon>
                    <ListItemText primary="Analitica" />
                </ListItem>

                <ListSubheader>CUOTAS</ListSubheader>
                <ListItem button component={Link} to="/cuotas" >
                    <ListItemIcon><ViewListIcon /></ListItemIcon>
                    <ListItemText primary="Previsión de cuotas" />
                </ListItem>
                <ListItem button component={Link} to="/vision_cuotas" >
                    <ListItemIcon><ViewListIcon /></ListItemIcon>
                    <ListItemText primary="Visión de cuotas" />
                </ListItem>

                <ListSubheader>CONTABILIDAD</ListSubheader>

                <ListItem button component={Link} to="/ms_conta" >
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText primary="MS Conta" />
                </ListItem>
                
                <Divider />

                </>
            }


            {/* Comercial: perfil_id = 2 */}
            { 
                (usuarioSeleccionado.perfil_id === "2" ) && 
                <>
                <ListSubheader>ALUMNOS</ListSubheader>
                <ListItem button component={Link} to="/alumnos" >
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText primary="CRM" />
                </ListItem>
                <Divider />
                <ListSubheader>ADMINISTRACIÓN</ListSubheader>
                <ListItem button component={Link} to="/alumnos_registrados" >
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText primary="Alumnos Registrados" />
                </ListItem>
                <ListItem button component={Link} to="/accesos_tutea" >
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText primary="Accesos Tutea" />
                </ListItem>
                <ListItem button component={Link} to="/alumnos_baja" >
                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                    <ListItemText primary="Alumnos Baja" />
                </ListItem>
                <ListItem button component={Link} to="/facturas" >
                    <ListItemIcon><PictureAsPdfIcon /></ListItemIcon>
                    <ListItemText primary="Facturas" />
                </ListItem>

                <ListSubheader>CUOTAS</ListSubheader>
                <ListItem button component={Link} to="/cuotas" >
                    <ListItemIcon><ViewListIcon /></ListItemIcon>
                    <ListItemText primary="Previsión de cuotas" />
                </ListItem>
                <ListItem button component={Link} to="/vision_cuotas" >
                    <ListItemIcon><ViewListIcon /></ListItemIcon>
                    <ListItemText primary="Visión de cuotas" />
                </ListItem>

                </>
            }
            {/* Analitica: perfil_id = 3 */}
            { 
                (usuarioSeleccionado.perfil_id === "3" ) && 
                <>
                    <ListSubheader>ADMINISTRACIÓN</ListSubheader>
                    <ListItem button component={Link} to="/analitica" >
                        <ListItemIcon><ViewListIcon /></ListItemIcon>
                        <ListItemText primary="Analitica" />
                    </ListItem>

                </>
            }
            {/* Contabilidad: perfil_id = 4 */}
            { 
                (usuarioSeleccionado.perfil_id === "4" ) && 
                <>
                    <ListSubheader>CONTABILIDAD</ListSubheader>
                    <ListItem button component={Link} to="/ms_conta" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="MS Conta" />
                    </ListItem>
                </>
            }
            {/* Atencion al alumno: perfil_id = 5 */}
            { 
                (usuarioSeleccionado.perfil_id === "5" ) && 
                <>
                    <ListSubheader>ADMINISTRACIÓN</ListSubheader>
                    <ListItem button component={Link} to="/alumnos_registrados" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Alumnos Registrados" />
                    </ListItem>
                    <ListItem button component={Link} to="/accesos_tutea" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Accesos Tutea" />
                    </ListItem>
                    <ListItem button component={Link} to="/alumnos_baja" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Alumnos Baja" />
                    </ListItem>
                    <ListItem button component={Link} to="/facturas" >
                        <ListItemIcon><PictureAsPdfIcon /></ListItemIcon>
                        <ListItemText primary="Facturas" />
                    </ListItem>
                    <ListItem button component={Link} to="/cuotas" >
                        <ListItemIcon><ViewListIcon /></ListItemIcon>
                        <ListItemText primary="Previsión de cuotas" />
                    </ListItem>

                </>
            }
            {/* Atencion al alumno andalucia: perfil_id = 6 */}
            { 
                (usuarioSeleccionado.perfil_id === "6" ) && 
                <>
                    <ListSubheader>ALUMNOS</ListSubheader>
                    <ListItem button component={Link} to="/alumnos" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="CRM" />
                    </ListItem>
                    <ListItem button component={Link} to="/alumnos_registrados_andalucia" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Alumnos Registrados" />
                    </ListItem>
                </>
            }
            {/* Marketing: perfil_id = 7 */}
            { 
                (usuarioSeleccionado.perfil_id === "7" ) && 
                <>
                    <ListSubheader>ADMINISTRACIÓN</ListSubheader>
                    <ListItem button component={Link} to="/alumnos_registrados" >
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Alumnos Registrados" />
                    </ListItem>

                </>
            }

            <Divider />

            



            <ListItem button component={Link} to="/logout" >
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary="Salir" />
            </ListItem>

            
        </div>
    );

    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const [openModalSearch, setOpenModalSearch] = useState(false)

    let history = useHistory();
    return (
        <>
            <AppBar position="fixed" className={classes.appbar}>
                <Toolbar>
                    <Grid justify={"space-between"} container>
                        <Grid xs={1} item>
                            {showBack ?
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => history.goBack()}>
                                    <ArrowBackIosIcon />
                                </IconButton>
                                :
                                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(anchor, true)}>
                                    <MenuIcon />
                                </IconButton>
                            }
                        </Grid>
                        <Grid xs={9} justifyContent='center' item>
                            <Typography variant="h6" className={classes.title}>
                                {title == null ? '.:: Innovatiio Gestión ::.' : title}
                            </Typography>
                        </Grid>
                        <Grid xs={1} justifyContent='end' item style={{display: 'flex'}}>
                            <Button onClick={ () => { setOpenModalSearch(true) } }><SearchIcon style={{color: 'white'}} /></Button>
                        </Grid>
                    </Grid>
                        <Grid item>{usuarioSeleccionado.username}</Grid>
                    {/* <Button color="inherit" component={Link} to="/logout" onClick={() => { }}>Salir</Button> */}
                </Toolbar>
            </AppBar>

            <React.Fragment key={anchor}>
                <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                    disableBackdropTransition={!iOS}
                    disableDiscovery={iOS}
                >
                    <img src={avatar} className={classes.avatar} alt="avatar" />
                    {list(anchor)}
                </SwipeableDrawer>
            </React.Fragment>

            <DialogModalSearch 
                openModalSearch={openModalSearch}
                setOpenModalSearch={setOpenModalSearch}
            />
        </>
    );
}

export default NavbarTop;