import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles, withStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../../components/EmptyContent';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import AddIcon from '@material-ui/icons/Add';
import { getAlumnos, getAvisosUsuario, update_comercial } from '../../../../services/alumnos';
import { Stack } from '@mui/system';
import {sincronizar_formularios, sincronizar_notion, sincronizar_woo_order} from '../../../../services/sincronizar'
import { getComerciales } from '../../../../services/comerciales';
import { getCuotasMes, getFacturas, getProximasCuotas } from '../../../../services/cuotas';
import Moment from 'react-moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { actualizar_factura_alumno_producto_cuota } from '../../../../services/alumnos_productos_cuotas';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function AvisosContent() {
    const classes = useStyles();

    const [avisos, setAvisos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando facturas")

        const usuario_id = usuarioSeleccionado.id

        getAvisosUsuario({usuario_id}).then ( (response) => {
          response && setAvisos(response.avisos)
          setLoading(false)
          // console.log(response)
      })

    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */
    const columnas = [
        { field: 'id', hide: true, headerName: 'ID' },
        { field: 'alumno_nombre', headerName: 'Alumno', width: '300'},
        { field: 'nota', headerName: 'Nota', flex: 1},
        {
          'field': 'fecha_aviso', 
          'width':120, 
          'headerName': 'Fecha aviso', 
          'description': 'Fecha aviso',
          type: 'date',
          renderCell: (cellValues) => {
            return (
              
                (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
        
                <Moment format="DD/MM/YYYY">
                  {cellValues.value}
                </Moment>
        
                :
        
                <></>
        
              
            );
          } 
        },
    ];

    // const facturaActualizarDatosAlumno = (params) => {
    //     Swal.fire({
    //         title: '¿Realmente deseas actualizar los datos del alumno?',
    //         showCancelButton: true,
    //         confirmButtonText: 'Si',
    //         cancelButtonText: `No`,
    //         confirmButtonColor: '#afcb0d',
    //         cancelButtonColor: '#a5a5a5',
    //         icon: 'warning',
    //         reverseButtons: true
    //       }).then((result) => {

    //         /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {
                
    //             const usuario_id = usuarioSeleccionado.id
    //             actualizar_factura_alumno_producto_cuota( {usuario_id: usuario_id, factura_id: params.id} ).then((response) => {

    //                 response && setFacturas(response.facturas)
                    
    //             })

    //         } else if (result.isDenied) {
    //         //   Swal.fire('Changes are not saved', '', 'info')
    //         }
    //       })
    // }
    // const handleAlumnoDetalle = (row) => {
    //   history.push('alumnos/detalle/' + row.alumno_id )
    // }
    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */

    const Contenido = () => {

        const handleAlumnoDetalle = (id) => {
          history.push('alumnos/detalle/' + id )
        }

        return (
                <>
                    <TituloPagina titulo={"Avisos"} />

                    
                    <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                        <DataGrid 
                            rows={avisos} 
                            columns={columnas} 
                            rowHeight={30}
                            getRowHeight={ () => 'auto' }
                            onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.row.alumno_id) }
                            
                        />
                    </div>
                    
                    {/* <Typography variant='caption' style={{marginTop: 10, marginLeft: 16}}>NOTA: Ordenado por fecha de entrada de gasto decreciente</Typography> */}

                </>
                
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
