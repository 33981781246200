import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import FacturasContent from './components/FacturasContent';

const FacturasListado = () => {

    return (
        <>

            <TemplateDashboard>
                <FacturasContent />
            </TemplateDashboard>

        </>
    )
};

export default FacturasListado;