import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
import { dateToMysql } from '../utils/dateTime';

/* -------------------------------------------------------------------------- */
/*                               Insertar nota                               */
/* -------------------------------------------------------------------------- */
export async function sincronizar_woo_order_alumnos({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/sincro_woocommerce',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function update_nota_fecha_aviso({ usuario_id, nota_id, fecha_aviso }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("nota_id", nota_id);
        formData.append("fecha_aviso", dateToMysql(fecha_aviso));

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/update_nota_fecha_aviso',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

