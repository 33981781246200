import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import { gridFilteredRowsLookupSelector, useGridApiRef} from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { getCuotasMesCobrado, getCuotasMesPendiente, getCuotasMesPendientePasado, getProximasCuotas } from '../../../../services/cuotas';
import DataGridInnovattio from '../../../../components/DataGridInnovatiio';
import { columnasCuotas } from './ColumnasCuotas';
import { vAlumnosProductosCuotasPrevisionMeses, valumnos_productos_cuotas_prevision_meses } from '../../../../services/alumnos';

import Chart from "react-apexcharts";
/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '20px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function CuotasEvolucionContent() {
    const classes = useStyles();

    const [prevision, setPrevision] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [periodos, setPeriodos] = useState([])
    const [importes, setImportes] = useState([])
    const [previsionPeriodos, setPrevisionPeriodos] = useState([])

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando cuotas")

        const usuario_id = usuarioSeleccionado.id

        vAlumnosProductosCuotasPrevisionMeses({ usuario_id }).then((response) => {
          response && setPrevision(response.prevision)
          
          // console.log(response.prevision)

          // let periodos_aux = []
          // let importes_aux = []
          // response && response.prevision.forEach(element => {
          //   periodos_aux.push(element.periodo)
          //   importes_aux.push(element.importe)
          // });
          // setPeriodos(periodos_aux)
          // setImportes(importes_aux)
          
          // let prevision_aux = []
          // response && response.prevision.forEach(element => {
          //   if(prevision_aux.indexOf(element.year) === -1) {
          //     prevision_aux.push(element.year) 
          //     prevision_aux[element.year] = []

          //     console.log(element.year)
          //   }

          //   prevision_aux[element.year].push(element)
          //   console.log(prevision_aux)
          // });
          // setPrevisionPeriodos(prevision_aux)
          // console.log(prevision_aux)


          setLoading(false)
      });

    }, [])
    
    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */
    const state = {
      options: {
        chart: {
          height: 300,
          width: 500,
          id: "basic-bar"
        },
        xaxis: {
          categories: periodos
        }
      },
      series: [
        {
          name: "Importes",
          data: importes
        },
        
      ]
    };
  

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    const StyledTableRowFooter = withStyles((theme) => ({
      root: {
        '&:foot': {
          backgroundColor: 'black',
        },
      },
    }))(TableRow);

    const Contenido = () => {
      let sum_importe = 0
      return (
        <>
        
          <div style={{ padding: "0 16px" }}>
            

                  {         
                               
                    Object.values(prevision).map( (items_year) => {
                      sum_importe = 0
                      return (
                      <>
                      <TableContainer component={Paper} >
                        <Table className={classes.table} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow style={{backgroundColor: '#0b314E', color: 'white'}}>
                              <TableCell style={{color: 'white'}} align="left">Periodo</TableCell>
                              <TableCell style={{color: 'white'}} align="right">Importe</TableCell>
                              <TableCell style={{color: 'white'}} align="right">Num. cuotas</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {
                              items_year.map( (row) => {
                                sum_importe += parseFloat(row.importe)
                                return (
                                <TableRow key={row.periodo}>
                                  <TableCell align="left">{row.periodo}</TableCell>
                                  <TableCell align="right">{ parseFloat(row.importe).toLocaleString('es',{ minimumFractionDigits: 2 }) }€</TableCell>
                                  <TableCell align="right">{row.num_cuotas}</TableCell>
                                </TableRow>
                                )
                              })
                            }                 
                 
                            <TableRow style={{backgroundColor: '#e0e0e0'}}>
                              <TableCell align="left"></TableCell>
                              <TableCell align="right">{ parseFloat(sum_importe).toLocaleString('es',{ minimumFractionDigits: 2 }) }€</TableCell>
                              <TableCell align="right"></TableCell>
                            </TableRow>

                          </TableBody>
                        </Table>
                      </TableContainer>

<br />                      
                      </>
                      )
                    })
                  
                  }
                
          </div>

          {/* <div style={{ height: 100 }}>

            <Chart
              options={state.options}
              series={state.series}
              type="bar"
            />

          </div> */}
        </>
              
      )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
