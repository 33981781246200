import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
import { dateToMysql } from '../utils/dateTime';

/* -------------------------------------------------------------------------- */
/*                            Insertar cuota                               */
/* -------------------------------------------------------------------------- */
export async function msconta_exportar({ usuario_id, fecha_desde, fecha_hasta }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("fecha_desde", dateToMysql (fecha_desde));
        formData.append("fecha_hasta", dateToMysql (fecha_hasta));

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'msconta/exportar',
           data: formData,
           responseType: 'blob', // important
            headers: {
                // 'Content-Disposition': "attachment; filename=template.xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                // 'Content-Type': 'blob',
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function msconta_filtrar_cobro({ usuario_id, fecha_desde, fecha_hasta, tipo }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("fecha_desde", dateToMysql (fecha_desde));
        formData.append("fecha_hasta", dateToMysql (fecha_hasta));
        formData.append("tipo", tipo);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/obtener_alumnos_productos_cobros_tipo',
           data: formData,
            headers: {
                // 'Content-Disposition': "attachment; filename=template.xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                // 'Content-Type': 'blob',
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
export async function msconta_obtener_woo_order_fechas({ usuario_id, fecha_desde, fecha_hasta }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("fecha_desde", dateToMysql (fecha_desde));
        formData.append("fecha_hasta", dateToMysql (fecha_hasta));

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/obtener_woo_order_fechas',
           data: formData,
            headers: {
                // 'Content-Disposition': "attachment; filename=template.xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                // 'Content-Type': 'blob',
                // 'Content-Type': 'multipart/form-data',
                // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
                // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}


// /* -------------------------------------------------------------------------- */
// /*                            Eliminar cuota                               */
// /* -------------------------------------------------------------------------- */
// export async function eliminar_alumno_producto_cuota({ usuario_id, alumno_producto_cuota_id }) {
//     try {
//         let formData = new FormData();

//         formData.append("usuario_id", usuario_id);
//         formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

//         const response = await axios({
//            method: 'post',
//            url: BASE_URL_SERVICE + 'alumno/eliminar_alumno_producto_cuota',
//            data: formData,
//            headers: {
//             // 'Content-Type': 'multipart/form-data',
//             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
//             // 'X-AUTH-TOKEN': "1231231231231231"
//            },
//     //   crossorigin: true,    
//     //   mode: 'no-cors',      
//         });

//         return response.data;
//     } catch (error) {
//         return undefined;
//         // throw error;
//     }

// }

// /* -------------------------------------------------------------------------- */
// /*                            Enviar enlace de pago                           */
// /* -------------------------------------------------------------------------- */
// export async function enviar_enlace_pago_alumno_producto_cuota({ usuario_id, alumno_producto_cuota_id }) {
//     try {
//         let formData = new FormData();

//         formData.append("usuario_id", usuario_id);
//         formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

//         const response = await axios({
//            method: 'post',
//            url: BASE_URL_SERVICE + 'alumno/enviar_enlace_pago_alumno_producto_cuota',
//            data: formData,
//            headers: {
//             // 'Content-Type': 'multipart/form-data',
//             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
//             // 'X-AUTH-TOKEN': "1231231231231231"
//            },
//     //   crossorigin: true,    
//     //   mode: 'no-cors',      
//         });

//         return response.data;
//     } catch (error) {
//         return undefined;
//         // throw error;
//     }

// }

// /* -------------------------------------------------------------------------- */
// /*                            Enviar enlace de pago                           */
// /* -------------------------------------------------------------------------- */
// export async function enviar_cobro_alumno_producto_cuota({ usuario_id, alumno_producto_cuota_id }) {
//     try {
//         let formData = new FormData();

//         formData.append("usuario_id", usuario_id);
//         formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

//         const response = await axios({
//            method: 'post',
//            url: BASE_URL_SERVICE + 'alumno/enviar_cobro_alumno_producto_cuota',
//            data: formData,
//            headers: {
//             // 'Content-Type': 'multipart/form-data',
//             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
//             // 'X-AUTH-TOKEN': "1231231231231231"
//            },
//     //   crossorigin: true,    
//     //   mode: 'no-cors',      
//         });

//         return response.data;
//     } catch (error) {
//         return undefined;
//         // throw error;
//     }

// }

// /* -------------------------------------------------------------------------- */
// /*                            Enviar enlace de pago                           */
// /* -------------------------------------------------------------------------- */
// export async function enviar_cobro_manual_alumno_producto_cuota({ usuario_id, alumno_producto_cuota_id }) {
//     try {
//         let formData = new FormData();

//         formData.append("usuario_id", usuario_id);
//         formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

//         const response = await axios({
//            method: 'post',
//            url: BASE_URL_SERVICE + 'alumno/enviar_cobro_manual_alumno_producto_cuota',
//            data: formData,
//            headers: {
//             // 'Content-Type': 'multipart/form-data',
//             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
//             // 'X-AUTH-TOKEN': "1231231231231231"
//            },
//     //   crossorigin: true,    
//     //   mode: 'no-cors',      
//         });

//         return response.data;
//     } catch (error) {
//         return undefined;
//         // throw error;
//     }

// }

// /* -------------------------------------------------------------------------- */
// /*                            Enviar enlace de pago                           */
// /* -------------------------------------------------------------------------- */
// export async function crear_factura_alumno_producto_cuota({ usuario_id, alumno_producto_cuota_id }) {
//     try {
//         let formData = new FormData();

//         formData.append("usuario_id", usuario_id);
//         formData.append("alumno_producto_cuota_id", alumno_producto_cuota_id);

//         const response = await axios({
//            method: 'post',
//            url: BASE_URL_SERVICE + 'alumno/crear_factura_alumno_producto_cuota',
//            data: formData,
//            headers: {
//             // 'Content-Type': 'multipart/form-data',
//             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
//             // 'X-AUTH-TOKEN': "1231231231231231"
//            },
//     //   crossorigin: true,    
//     //   mode: 'no-cors',      
//         });

//         return response.data;
//     } catch (error) {
//         return undefined;
//         // throw error;
//     }

// }

// /* -------------------------------------------------------------------------- */
// /*                            Enviar enlace de pago                           */
// /* -------------------------------------------------------------------------- */
// export async function actualizar_factura_alumno_producto_cuota({ usuario_id, factura_id }) {
//     try {
//         let formData = new FormData();

//         formData.append("usuario_id", usuario_id);
//         formData.append("factura_id", factura_id);

//         const response = await axios({
//            method: 'post',
//            url: BASE_URL_SERVICE + 'alumno/actualizar_factura_alumno_producto_cuota',
//            data: formData,
//            headers: {
//             // 'Content-Type': 'multipart/form-data',
//             // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
//             // 'X-AUTH-TOKEN': "1231231231231231"
//            },
//     //   crossorigin: true,    
//     //   mode: 'no-cors',      
//         });

//         return response.data;
//     } catch (error) {
//         return undefined;
//         // throw error;
//     }

// }

