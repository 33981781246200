import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'

/* -------------------------------------------------------------------------- */
/*                               Get Propiedades                              */
/* -------------------------------------------------------------------------- */
export async function getListaDetalle({ usuario_id, lista_id }) {
    console.log(lista_id)
    try {
        var config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'text/json'
            }
        };

        const response = await axios.get(BASE_URL_SERVICE + 'listaDetalle'  , {
            params: {
                usuario_id,
                lista_id
            }
        }, config);

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}
