import axios from 'axios';
import {
	BASE_URL_SERVICE,
} from '../constants'

async function getUsuario(mail, password) {
	// alert('Mail: ' + mail + 'Pass: ' + password)
	// alert(BASE_URL_SERVICE)

	try {
		var config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				// 'content-type': 'application/form-data'
			}
		};
		var params = new URLSearchParams();
		params.append('mail', mail);
		params.append('password', password);
		const response = await axios.post(BASE_URL_SERVICE + 'usuario', params, config);

		return response;
	} catch (error) {
		// throw error;
		return undefined;
	}

}

export default getUsuario;