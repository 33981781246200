import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AvisosContent from './components/AvisosContent';

const AvisosListado = () => {

    return (
        <>

            <TemplateDashboard>
                <AvisosContent />
            </TemplateDashboard>

        </>
    )
};

export default AvisosListado;