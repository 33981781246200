import { Box, Divider, Typography } from "@material-ui/core"

const TituloForm = ({titulo}) => {
    return (
        <>
        <Box mb={2}>
            <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >
                {titulo}
            </Typography>
            <Divider />
        </Box>
        </>
    )
}

export default TituloForm