import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AlumnosContent from './components/AlumnosContent';

const AlumnosListado = () => {

    return (
        <>

            <TemplateDashboard>
                <AlumnosContent />
            </TemplateDashboard>

        </>
    )
};

export default AlumnosListado;