import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import CuotasContent from './components/CuotasContent';

const CuotasListado = () => {

    return (
        <>

            <TemplateDashboard>
                <CuotasContent />
            </TemplateDashboard>

        </>
    )
};

export default CuotasListado;