import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import EmptyComponent from '../../../../components/EmptyContent';
import { Box, Button, Card, CardActions, CardContent, CardHeader, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../../../redux/reducer/loginReducer';
import { getAlumnosRegistradosBuscar } from '../../../../services/alumnos';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Stack } from '@mui/material';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
}));

export default function BuscarAlumnoContent() {
    const classes = useStyles();


    let history = useHistory();

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    /* -------------------------------------------------------------------------- */
    /*                                   Formik                                   */
    /* -------------------------------------------------------------------------- */
    
    
    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    

    const Contenido = () => {
      const [alumnos, setAlumnos] = useState([]);

      const [initialValues, setInitialValues] = useState({
        telefono: '',
        correo_electronico: '',
      })
      const validationSchema = yup.object({
          
          // nombre: yup.string().required('Nombre es requerido'),
          correo_electrnico: yup.string().email() //.required('Correo es requerido'),
          
      });
        
      const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {

          if(values.telefono === '' && values.correo_electronico === '' ) {
            actions.setFieldError('telefono', 'Telefono o correo electrónico obligatorios')
            actions.setFieldError('correo_electronico', 'Telefono o correo electrónico obligatorios')
            return;
          }

          getAlumnosRegistradosBuscar({usuario_id: usuarioSeleccionado.id, telefono: values.telefono, correo_electronico: values.correo_electronico}).then( (response) => {
            
            setAlumnos(response.alumnos)

          } )  

        }
      })

        return (
          <>
            <TituloPagina titulo={"Búsqueda de alumnos"} />
          
            <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={ (e) => {e.preventDefault(); formik.handleSubmit(e) }}>

              <Grid container  spacing={2} style={{flex: 1, width: '100%', paddingLeft: 15}}>
                <Grid item xs={8} md={5}>
                  <TextField
                    id="telefono"
                    name='telefono'
                    label="Telefono"
                    placeholder="Introduce el teléfono"
                    value={formik.values.telefono}
                    onChange={ formik.handleChange }
                    variant="outlined"
                    fullWidth
                    error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                    helperText={formik.touched.telefono && formik.errors.telefono}
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                  />
                </Grid>
                <Grid item xs={8} md={5}>
                  <TextField
                    id="correo_electronico"
                    name='correo_electronico'
                    label="Correo electrónico"
                    placeholder="Introduce el correo"
                    value={formik.values.correo_electronico}
                    onChange={ formik.handleChange }
                    variant="outlined"
                    fullWidth
                    error={formik.touched.correo_electronico && Boolean(formik.errors.correo_electronico)}
                    helperText={formik.touched.correo_electronico && formik.errors.correo_electronico}
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                  />
                </Grid>
                <Grid item xs={8} md={2}>
                  <Button 
                    fullWidth
                    variant="contained" 
                    color="primary" 
                    type="submit"
                    size='large'
                    style={{height: 55}}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>

            </form>

            <Grid container spacing={2} style={{paddingLeft: 15, paddingRight: 15}}>
              {alumnos.map( (alumno) => {
                return (
                  <Grid key={alumno.id} item xs={10} sm={6} lg={4}>
                    <Card>
                      <CardHeader title={alumno.nombre} />
                      <CardContent>
                        <Stack direction="column">
                          <Box><b>Telefono:</b> {alumno.telefono}</Box>
                          <Box><b>Correo electrónico:</b> {alumno.correo_electronico}</Box>
                        </Stack>
                      </CardContent>
                      <CardActions>
                        <Button size="small" onClick={ () => {
                          history.push('/alumnos/detalle/' + alumno.id)
                        } }>Abrir Ficha</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          </>
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                    <Contenido />
            }
        </div>
    )
}
