import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../../components/EmptyContent';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
// import { GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import AddIcon from '@material-ui/icons/Add';
import { getAlumnos, update_comercial } from '../../../../services/alumnos';
import { Stack } from '@mui/system';
import {sincronizar_formularios, sincronizar_notion, sincronizar_woo_order} from '../../../../services/sincronizar'
import { getComerciales } from '../../../../services/comerciales';
import Moment from 'react-moment';
import { DataGridPro, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import DataGridInnovattio from '../../../../components/DataGridInnovatiio';
import moment from 'moment/moment';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function AlumnosContent() {
    const classes = useStyles();

    const [Alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    const [selectionModel, setSelectionModel] = React.useState([]);
    const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando alumnos")

        const usuario_id = usuarioSeleccionado.id

        getAlumnos({ usuario_id }).then((response) => {
          setLoading(false)

            response && setAlumnos(response.alumnos)
        });

        getComerciales({usuario_id: usuario_id}).then( (response) => {
            response.comerciales && setComerciales(response.comerciales)
        })
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    usuarioSeleccionado.perfil_id === '1' ?
    [
        { field: 'id', headerName: 'ID' },
        { field: 'nombre', headerName: 'Nombre', minWidth: 150, flex: 1 },
        {
          'field': 'created', 
          'width':200, 
          'headerName': 'Añadido', 
          'description': 'Añadido',
          type: 'date',
          valueFormatter: params => {
            return moment(params?.value).format("DD/MM/YYYY hh:mm A")
          },
          renderCell: (cellValues) => {
            return (
              
                (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
        
                <Moment format="DD/MM/YYYY HH:mm:ss">
                  {cellValues.value}
                </Moment>
        
                :
        
                <></>
        
              
            );
          } 
      },
      { field: 'correo_electronico', headerName: 'Correo electrónico', minWidth: 250, flex: 1 },
        {
            'field': 'telefono', 
            'width':150, 
            'headerName': 'Teléfono', 
            'description': 'Teléfono',
            renderCell: (cellValues) => {
                return (
                    <a className={classes.enlace} href={`tel:${cellValues.value}`}>
                        {cellValues.value}
                    </a>
                );
            } 
        },
        // {
        //     field: 'telefono_espacios', 
        //     hide: true,
        //     width:150, 
        //     headerName: 'Teléfono espacios', 
        // },
        { field: 'temperatura_nombre', headerName: 'Temperatura',minWidth: 120 },
        { 
          field: 'es_lead_valido', 
          headerName: 'Lead válido', 
          minWidth: 150, 
          renderCell: (cellValues) => {
            return (
              <>{cellValues.row.es_lead_valido === "1" ? "SI" : "NO"}</>
            )
          }
        },
        { field: 'status_nombre', headerName: 'Estado',minWidth: 120 },
        { field: 'comercial_nombre', headerName: 'Comercial',minWidth: 100, flex: 1 },
        { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad',minWidth: 100, flex: 1 },
        { field: 'oposicion_nombre', headerName: 'Oposición',minWidth: 100, flex: 1 },
        { field: 'especialidad_nombre', headerName: 'Especialidad',minWidth: 10, flex: 1 },
        { field: 'como_conocido_nombre_lista', headerName: 'Como conocido',minWidth: 10, flex: 1 },
        // { field: 'formulario_nombre', headerName: 'Formulario',minWidth: 10, flex: 1 },
        {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Modificar"
              component={Link}
              to={`alumnos/detalle/${params.id}`}
              showInMenu
            />,
            // <GridActionsCellItem
            //   icon={<DeleteIcon />}
            //   label="Eliminar"
            //   onClick={() => linkDelete(params.id)}
            //   showInMenu
            // />,
          ],
        }
    ]
    
    :

    [
      { field: 'id',hide: true, headerName: 'ID', minWidth: 10 },
      { field: 'nombre', headerName: 'Nombre', minWidth: 250 },
      { field: 'correo_electronico', headerName: 'Correo electrónico', minWidth: 300 },
      {
          'field': 'telefono', 
          'width':100, 
          'headerName': 'Teléfono', 
          'description': 'Teléfono',
          renderCell: (cellValues) => {
              return (
                  <a className={classes.enlace} href={`tel:${cellValues.value}`}>
                      {cellValues.value}
                  </a>
              );
          } 
      },
    //   {
    //     field: 'telefono_espacios', 
    //     hide: true,
    //     width:150, 
    //     headerName: 'Teléfono espacios', 
    // },
      { field: 'temperatura_nombre', headerName: 'Temperatura',minWidth: 100 },
      { 
        field: 'es_lead_valido', 
        headerName: 'Lead válido', 
        minWidth: 100, 
        type: 'boolean',
        valueGetter: params => params?.value === '1' ? true : false,
        renderCell: (cellValues) => {
          return (
            <>{cellValues.row.es_lead_valido === "1" ? "SI" : "NO"}</>
          )
        }
      },
      { field: 'status_nombre', headerName: 'Estado',minWidth: 120 },
      { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad',minWidth: 100, flex: 1 },
      { field: 'oposicion_nombre', headerName: 'Oposición',minWidth: 90 },
      { field: 'especialidad_nombre', headerName: 'Especialidad',minWidth: 220 },
      { field: 'como_conocido_nombre_lista', headerName: 'Como conocido',minWidth: 200 },
      // { field: 'formulario_nombre', headerName: 'Formulario',minWidth: 10, flex: 1 },
      {
        'field': 'ultimo_contacto', 
        'width':120, 
        'headerName': 'Ult. contacto', 
        'description': 'Último contacto',
        type: 'date',
        valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      {
        'field': 'proximo_contacto', 
        'width':120, 
        'headerName': 'Prox. contacto', 
        'description': 'Próximo contacto',
        type: 'date',
        valueFormatter: params => moment(params?.value).format("DD/MM/YYYY hh:mm A"),
        renderCell: (cellValues) => {
          return (
            
              (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
      
              <Moment format="DD/MM/YYYY">
                {cellValues.value}
              </Moment>
      
              :
      
              <></>
      
            
          );
        } 
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Modificar"
            component={Link}
            to={`alumnos/detalle/${params.id}`}
            showInMenu
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Eliminar"
          //   onClick={() => linkDelete(params.id)}
          //   showInMenu
          // />,
        ],
      }
    ]
    ;

    // const linkDelete = (id) => {
    //     Swal.fire({
    //         title: '¿Realmente deseas eliminar el inquilino?',
    //         showCancelButton: true,
    //         confirmButtonText: 'Eliminar',
    //         cancelButtonText: `Cancelar`,
    //         confirmButtonColor: '#afcb0d',
    //         cancelButtonColor: '#a5a5a5',
    //         icon: 'warning',
    //         reverseButtons: true
    //       }).then((result) => {

    //         /* Read more about isConfirmed, isDenied below */
    //         if (result.isConfirmed) {
                
    //         //     console.log(id)
    //             const usuario_id = usuarioSeleccionado.id

    //             inquilinoDelete( usuario_id, id ).then((response) => {
                    
    //                 response && setAlumnos(response.data.Alumnos)
                    
    //             })

    //         } else if (result.isDenied) {
    //         //   Swal.fire('Changes are not saved', '', 'info')
    //         }
    //       })
    // }
    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function QuickSearchToolbar(props) {
        const classes = useStyles();
      
        return (
          <div className={classes.rootFilter}>
            <div>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
            </div>
            <TextField
              variant="standard"
              value={props.value}
              onChange={props.onChange}
              placeholder="Buscar…"
              className={classes.textFieldFilter}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: props.value ? 'visible' : 'hidden' }}
                    onClick={props.clearSearch}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </div>
        );
      }
      
      QuickSearchToolbar.propTypes = {
        clearSearch: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
      };

    const Contenido = () => {
        const [rows, setRows] = React.useState(Alumnos);
        const [searchText, setSearchText] = React.useState('');
        const requestSearch = (searchValue) => {
          // console.log(searchValue)
          setSearchText(searchValue);
          const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
          // const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
          const filteredRows = Alumnos.filter((row) => {
              return Object.keys(row).some((field) => {
                  // console.log(field)
                  // console.log(row)
                  return searchRegex.test(row[field]);
              });
          });
          setRows(filteredRows);
        };

        const handleAlumnoDetalle = (id) => {
          history.push('alumnos/detalle/' + id )
        }

        const handleAsignarComercial = () => {
          let usuario_id = usuarioSeleccionado.id
          selectionModel.map( (alumno_id) => {
            update_comercial({
              usuario_id: usuario_id,
              alumno_id: alumno_id,
              comercial_id: comercialSeleccionado
            }).then( (response) => {
              setLoading(true)
              getAlumnos({ usuario_id: usuario_id }).then((response) => {

                response && setAlumnos(response.alumnos)
                setLoading(false)
                
            });
            })
          })
        }
        // const [filterModel, setFilterModel] = React.useState({
        //   items: [
        //     { id: 1, field: 'status_nombre', operator: 'not equals', value: 'Alumno' },
        //   ],
        // });
        return (
            rows ?
                <>
                    <TituloPagina titulo={"Alumnos"} />

                    <Stack direction='row' mb={2} mx={2} spacing={1}>

                      <Grid xs={6}>
                        <Stack direction='row' mb={2} spacing={1}>

                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            component={Link}
                            to="alumnos/create"
                          >
                            Añadir
                          </Button>

                          {usuarioSeleccionado.perfil_id === '1' && <a 
                            href="https://gestion.innovatiio.com/api/v1/sincronizar" 
                            target="_blank"
                            rel='noreferrer'
                          >
                            
                            <Button variant="contained" color="primary">
                              Opciones de sincronización
                            </Button>
      
                          </a>}
                      
                        </Stack>
                      </Grid>
                    </Stack> 

                    <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                    
                        <DataGridInnovattio
                            rows={rows} 
                            columns={columnas} 
                            rowHeight={30}
                            components={{ Toolbar: QuickSearchToolbar }}
                            onDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                            // checkboxSelection={usuarioSeleccionado.perfil_id === '1'}
                            // onSelectionModelChange={(newSelectionModel) => {
                            //   setSelectionModel(newSelectionModel);
                            // }}
                            // selectionModel={selectionModel}
                            componentsProps={{
                                toolbar: {
                                  value: searchText,
                                  onChange: (event) => requestSearch(event.target.value),
                                  clearSearch: () => requestSearch(''),
                                },
                            }}
                            // filterModel={filterModel}
                            // onFilterModelChange={(model) => setFilterModel(model)}
                        />
                    </div>

                    {/* <Typography variant='caption' style={{marginTop: 10, marginLeft: 16}}>NOTA: Ordenado por fecha de entrada de gasto decreciente</Typography> */}

                </>
                
                :
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
