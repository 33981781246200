import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AlumnosRegistradosAndaluciaContent from './components/AlumnosRegistradosAndaluciaContent';

const AlumnosRegistradosAndaluciaListado = () => {

    return (
        <>

            <TemplateDashboard>
                <AlumnosRegistradosAndaluciaContent />
            </TemplateDashboard>

        </>
    )
};

export default AlumnosRegistradosAndaluciaListado;