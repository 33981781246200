import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles, withStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Link, useHistory } from 'react-router-dom';
// import Moment from 'react-moment';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import { getAlumnos, getAlumnosPivot } from '../../../../services/alumnos';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import { msconta, msconta_exportar, msconta_filtrar_cobro, msconta_obtener_woo_order_fechas } from '../../../../services/msconta';
import TemplateForm from '../../../../components/TemplateForm/TemplateForm';
import { Box, Button, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import TituloForm from '../../../../components/Titulos/TituloForm';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Stack } from '@mui/system';
import Moment from 'react-moment';
import { DataGrid } from '@mui/x-data-grid';
import { sincronizar_woo_order_alumnos } from '../../../../services/alumnos_woocommerce';
import { DataGridPro } from '@mui/x-data-grid-pro';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function MscontaContent() {
    const classes = useStyles();

    // // const [facturas, setFacturas] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [cobros, setCobros] = useState([])
    const [tituloFiltro, setTituloFiltro] = useState("")
    const [esWoo, setEsWoo] = useState(false)
    
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()
    // const [alumnos, setAlumnos] = useState([]);

    const [fechaDesde, setFechaDesde] = React.useState(new Date());
    const [fechaHasta, setFechaHasta] = React.useState(new Date());

    const handleFechaDesde = (date) => {
        setFechaDesde(date);
    };
    const handleFechaHasta = (date) => {
        setFechaHasta(date);
    };

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */
    const columnas = [
        { field: 'id', hide: true, headerName: 'ID' },
        { field: 'alumno_nombre', headerName: 'Alumno', flex:1,  width: '300'},
        { field: 'num_serie_factura', headerName: 'Num factura'},
        { 
            field: 'importe_base', 
            headerName: 'Base',
            // type: "number",
            renderCell: (cellValues) => {
                return (
                  
                    
                      <span>{cellValues.value}€</span>
                    
                  
                );
              } 
        },
        { 
            field: 'iva', 
            headerName: 'IVA',
            // type: "number",
            renderCell: (cellValues) => {
                return (
                  
                    
                      <span>{cellValues.value}€</span>
                    
                  
                );
              } 
        },
        { 
            field: 'importe', 
            headerName: 'Importe',
            // type: "number",
            renderCell: (cellValues) => {
                return (
                  
                    
                      <span>{cellValues.value}€</span>
                    
                  
                );
              } 
        },
        {
          'field': 'fecha', 
          'width':120, 
          'headerName': 'Fecha factura', 
          'description': 'Fecha factura',
        //   type: 'date',
          renderCell: (cellValues) => {
            return (
              
                (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
        
                <Moment format="DD/MM/YYYY">
                  {cellValues.value}
                </Moment>
        
                :
        
                <></>
        
              
            );
          } 
        },
        {
          'field': 'fecha_cobro', 
          'width':120, 
          'headerName': 'Fecha cobro', 
          'description': 'Fecha cobro',
        //   type: 'date',
          renderCell: (cellValues) => {
            return (
              
                (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
        
                <Moment format="DD/MM/YYYY">
                  {cellValues.value}
                </Moment>
        
                :
        
                <></>
        
              
            );
          } 
        },
    ];

//     a.nombre as alumno_nombre,
//     `w`.`id` AS `id`,
//     `w`.`billing_address_1` AS ``,
//     `w`.`billing_city` AS ``,
//     `w`.`billing_country` AS ``,
//     `w`.`billing_email` AS ``,
//     `w`.`billing_phone` AS ``,
//     `w`.`billing_postcode` AS ``,
//     `w`.`billing_state` AS `billing_state`,
//     `w`.`billing_wooccm11` AS `dni`,
//     `w`.`order_id` AS ``,
//     `w`.`order_items` AS `order_items`,
//     `w`.`order_total` AS ``,
//     `w`.`paid_date` AS `paid_date`,
//     `w`.`post_status` AS `post_status`,
//     (
//         CASE WHEN ISNULL(`w`.`alumno_id`) THEN `a`.`id` ELSE `w`.`id`
//     END
// ) AS `alumno_id`

    const columnas_woo = [
        { field: 'id', hide: true, headerName: 'ID' },
        { field: 'alumno_nombre', headerName: 'Alumno', flex:1,  width: '300'},
        { field: 'billing_address_1', headerName: 'Dirección'},
        { field: 'billing_city', headerName: 'Ciudad'},
        { field: 'billing_email', headerName: 'Correo electrónico'},
        { field: 'billing_phone', headerName: 'Teléfono'},
        { field: 'billing_postcode', headerName: 'CP'},
        { field: 'billing_wooccm11', headerName: 'DNI'},
        { field: 'order_id', headerName: 'Num Pedido'},
        { field: 'order_items', headerName: 'Concepto'},
        { 
            field: 'order_total', 
            headerName: 'Importe',
            type: "number",
            renderCell: (cellValues) => {
                return (
                  
                    
                      <span>{cellValues.value}€</span>
                    
                  
                );
              } 
        },
        {
          'field': 'paid_date', 
          'width':120, 
          'headerName': 'Fecha ', 
          'description': 'Fecha ',
          type: 'date',
          renderCell: (cellValues) => {
            return (
              
                (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
        
                <Moment format="DD/MM/YYYY">
                  {cellValues.value}
                </Moment>
        
                :
        
                <></>
        
              
            );
          } 
        },
    ];

    /* -------------------------------------------------------------------------- */
    /*                            Definición de Handler                           */
    /* -------------------------------------------------------------------------- */
    const handleExportarMsConta = () => {
        const usuario_id = usuarioSeleccionado.id
        setTituloFiltro("")
        setCobros([])
        msconta_exportar({ 
            usuario_id:usuario_id,
            fecha_desde: fechaDesde,
            fecha_hasta: fechaHasta
        }).then((response) => {

            setEsWoo(false);

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'msconta.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }
    const handleFiltrarSerieC = () => {
        sincronizar_woo_order_alumnos({
            usuario_id: usuarioSeleccionado.id,
        }).then( (response) => {
            setTituloFiltro("Pedidos WooCommerce")
            setEsWoo(true)

            msconta_obtener_woo_order_fechas({
                usuario_id: usuarioSeleccionado.id,
                fecha_desde: fechaDesde,
                fecha_hasta: fechaHasta,
            }).then( (response) => {
                response && setCobros(response.woo_order)
            })
        })
    }
    const handleFiltrarTarjetas = () => {
        setCobros([])
        setEsWoo(false)

        msconta_filtrar_cobro({
            usuario_id: usuarioSeleccionado.id,
            fecha_desde: fechaDesde,
            fecha_hasta: fechaHasta,
            tipo: 1
        }).then( (response) => {
            setTituloFiltro("Cobros de tarjeta")
            response && setCobros(response.cobros)
            response && console.log(response.cobros)
        })
        console.log("Filtrar Tarjetas")
    }
    const handleFiltrarAbonos = () => {
        setCobros([])
        setEsWoo(false)

        msconta_filtrar_cobro({
            usuario_id: usuarioSeleccionado.id,
            fecha_desde: fechaDesde,
            fecha_hasta: fechaHasta,
            tipo: 3
        }).then( (response) => {
            setTituloFiltro("Abonos")
            response && setCobros(response.cobros)
            response && console.log(response.cobros)
        })
        console.log("Filtrar Abonos")
    }
    const handleFiltrarTransferencias = () => {
        setCobros([])
        setEsWoo(false)

        msconta_filtrar_cobro({
            usuario_id: usuarioSeleccionado.id,
            fecha_desde: fechaDesde,
            fecha_hasta: fechaHasta,
            tipo: 2
        }).then( (response) => {
            setTituloFiltro("Cobros de transferencias")
            
            response && setCobros(response.cobros)
        })
    }
    // const handleAlumnoDetalle = (id) => {
    //     history.push('alumnos/detalle/' + id )
    //   }
    // /* -------------------------------------------------------------------------- */
    // /*                                  Contenido                                 */
    // /* -------------------------------------------------------------------------- */

    const TablaCuotas = () => {

        return (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Alumno</TableCell>
                    <TableCell>Num Factura</TableCell>
                    <TableCell align="right">Base</TableCell>
                    <TableCell align="right">IVA</TableCell>
                    <TableCell align="right">Importe</TableCell>
                    <TableCell align="right">F. factura</TableCell>
                    <TableCell align="right">F. cobro</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  
                  { 
                  
                  loading && 
                  
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell colSpan={10} style={{textAlign: 'center'}}><LoadingComponent /> </TableCell>
                  </TableRow>
                  
                  }

                  {cobros.map((row, index) => {
                    
                    return (

                      <TableRow
                        key={row.cuota_id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        
                      >
                        <TableCell scope="row">{row.alumno_nombre}</TableCell>
                        <TableCell>{row.num_serie_factura}</TableCell>
                        <TableCell align="right">{row.importe_base}€</TableCell>
                        <TableCell align="right">{row.iva}€</TableCell>
                        <TableCell align="right">{row.importe}€</TableCell>
                        <TableCell align="right"><MostrarFecha fecha={row.fecha}/></TableCell>
                        <TableCell align="right"><MostrarFecha fecha={row.fecha_cobro}/></TableCell>
                      </TableRow>
                    )

                  })
                  }
                  
                </TableBody>
              </Table>
            </TableContainer> 
        )
    }
    const MostrarFecha = ({fecha}) => (fecha !== '0000-00-00 00:00:00') ? <Moment format="DD/MM/YYYY">{fecha}</Moment> : <>-</>

    const Contenido = () => {
        return (
                <>
                   <TituloPagina titulo={"Exportación a MS Conta"} />
                        
                    <Box sx={{marginX: 2}}>
                        <Paper style={{padding: 20}}>
                            <TituloForm titulo={"Filtro"} />

                            <form className={classes.formulario} noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={ () => {} }>

                                <Grid container  spacing={2}>

                                    <Grid item xs={6} md={2} style={{marginTop: 16}}>

                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                fullWidth
                                                id="fecha_desde"
                                                label="Fecha Desde"
                                                placeholder='Introduce la fecha desde'
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                value={fechaDesde}
                                                onChange={value => handleFechaDesde(value)}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date"
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>

                                    </Grid>

                                    <Grid item xs={6} md={2} style={{marginTop: 16}}>

                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                fullWidth
                                                id="fecha_hasta"
                                                label="Fecha Hasta"
                                                placeholder='Introduce la fecha hasta'
                                                inputVariant="outlined"
                                                format="dd/MM/yyyy"
                                                value={fechaHasta}
                                                onChange={value => handleFechaHasta(value)}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date"
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>

                                    </Grid>
                                </Grid>

                                <Box mt={5}>
                                    <Divider />
                                    <Box mt={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Stack direction="row" spacing={2} >
                            
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                onClick={ () => { handleExportarMsConta() }}
                                            >       
                                            Exportar a MSConta Serie P
                                            </Button>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                onClick={ () => { handleFiltrarSerieC() }}
                                            >       
                                            Filtrar Pedidos Serie C
                                            </Button>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                onClick={ () => { handleFiltrarTarjetas() }}
                                            >       
                                            Filtrar Tarjetas
                                            </Button>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                onClick={ () => { handleFiltrarAbonos() }}
                                            >       
                                            Filtrar Abonos
                                            </Button>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                onClick={ () => { handleFiltrarTransferencias() }}
                                            >       
                                            Filtrar Transferencias
                                            </Button>
                                        
                                        </Stack>
                                    </Box>
                                </Box>
                            
                            </form> 
                    
                        </Paper>
                        </Box>

                        <TituloPagina titulo={tituloFiltro} />

                        {
                            !esWoo && 
                            <div style={{ padding:"0 15px" }}>
                                <TablaCuotas />
                            </div>
                        }
                        { 
                            esWoo && <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                            { <DataGrid 
                                rows={cobros} 
                                columns={ columnas_woo } 
                                rowHeight={30}
                                getRowHeight={ () => 'auto' }
                                // onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.row.alumno_id) }
                            />}
                        </div>
                        }
                 </>
                
         )
    }
    
    // /* -------------------------------------------------------------------------- */
    // /*                                   Return                                   */
    // /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
