import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import MscontaContent from './components/MscontaContent';

const MSconta = () => {

    return (
        <>

            <TemplateDashboard>
                <MscontaContent />
            </TemplateDashboard>

        </>
    )
};

export default MSconta;