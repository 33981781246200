import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles, withStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../../components/EmptyContent';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import AddIcon from '@material-ui/icons/Add';
import { getAlumnos, update_comercial } from '../../../../services/alumnos';
import { Stack } from '@mui/system';
import {sincronizar_formularios, sincronizar_notion, sincronizar_woo_order} from '../../../../services/sincronizar'
import { getComerciales } from '../../../../services/comerciales';
import { getCuotasMes, getFacturas, getProximasCuotas } from '../../../../services/cuotas';
import Moment from 'react-moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { actualizar_factura_alumno_producto_cuota } from '../../../../services/alumnos_productos_cuotas';
import DataGridInnovattio from '../../../../components/DataGridInnovatiio';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function FacturasContent() {
    const classes = useStyles();

    const [facturas, setFacturas] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando facturas")

        const usuario_id = usuarioSeleccionado.id

        getFacturas({ usuario_id }).then((response) => {

          console.log(response.facturas)
            response && setFacturas(response.facturas)
            setLoading(false)
        });

    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */
    const columnas = [
        { field: 'id', hide: true, headerName: 'ID' },
        { field: 'num_serie_factura', headerName: 'Num factura', flex: 1 },
        { field: 'alumno_nombre', headerName: 'Alumno', flex: 1 },
        { field: 'alumno_dni', headerName: 'DNI', flex: 1 },
        { field: 'alumno_direccion', headerName: 'Direccion', flex: 1 },
        { field: 'alumno_codigo_postal', headerName: 'CP', flex: 1 },
        { field: 'alumno_ciudad', headerName: 'Ciudad', flex: 1 },
        { field: 'alumno_provincia', headerName: 'Provincia', flex: 1 },
        { field: 'alumno_pais', headerName: 'Pais', flex: 1 },
        { field: 'cuota_fecha', headerName: 'Cuota fecha', flex: 1 },
        { field: 'cuota_importe_base', headerName: 'Importa base', flex: 1 },
        { field: 'cuota_iva', headerName: 'IVA', minWidth: 150 },
        { field: 'cuota_importe', headerName: 'total', flex: 1 },
        {
          'field': 'fecha', 
          'width':120, 
          'headerName': 'Fecha', 
          'description': 'Fecha',
          type: 'date',
          renderCell: (cellValues) => {
            return (
              
                (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
        
                <Moment format="DD/MM/YYYY">
                  {cellValues.value}
                </Moment>
        
                :
        
                <></>
        
              
            );
          } 
        },
        {
          'field': 'imprimir_factura', 
          'width':50, 
          'headerName': '', 
          'description': '',
          renderCell: (cellValues) => {
            return (
              <a href={`https://gestion.innovatiio.com/api/v1/alumno/factura?id=${cellValues.row.id}`} target="_blank">
                <PictureAsPdfIcon />
              </a>
            )
          }
        },
        {
          field: 'actions',
          type: 'actions',
          width: 50,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Actualizar datos alumno"
              onClick={() => facturaActualizarDatosAlumno(params)}
              // component={Link}
              // to={`alumnos/detalle/${params.id}`}
              showInMenu
            />,
            // <GridActionsCellItem
            //   icon={<DeleteIcon />}
            //   label="Eliminar"
            //   onClick={() => linkDelete(params.id)}
            //   showInMenu
            // />,
          ],
        }
    ];

    const facturaActualizarDatosAlumno = (params) => {
        Swal.fire({
            title: '¿Realmente deseas actualizar los datos del alumno?',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: `No`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                const usuario_id = usuarioSeleccionado.id
                actualizar_factura_alumno_producto_cuota( {usuario_id: usuario_id, factura_id: params.id} ).then((response) => {

                    response && setFacturas(response.facturas)
                    
                })

            } else if (result.isDenied) {
            //   Swal.fire('Changes are not saved', '', 'info')
            }
          })
    }
    const handleAlumnoDetalle = (row) => {
      history.push('alumnos/detalle/' + row.alumno_id )
    }
    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function QuickSearchToolbar(props) {
      const classes = useStyles();
    
      return (
        <div className={classes.rootFilter}>
          <div>
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
          </div>
          <TextField
            variant="standard"
            value={props.value}
            onChange={props.onChange}
            placeholder="Buscar…"
            className={classes.textFieldFilter}
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? 'visible' : 'hidden' }}
                  onClick={props.clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </div>
      );
    }
    
    QuickSearchToolbar.propTypes = {
      clearSearch: PropTypes.func.isRequired,
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    };
    
    const Contenido = () => {
      const [rows, setRows] = React.useState(facturas);
      const [searchText, setSearchText] = React.useState('');
          const requestSearch = (searchValue) => {
            // console.log(searchValue)
            setSearchText(searchValue);
            const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
            // const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
            const filteredRows = facturas.filter((row) => {
                return Object.keys(row).some((field) => {
                    // console.log(field)
                    // console.log(row)
                    return searchRegex.test(row[field]);
                });
            });
            setRows(filteredRows);
          };

        return (
                <>
                    <TituloPagina titulo={"Facturas"} />

                    
                    <Box mx={2}><Typography variant='h6'>Facturas</Typography></Box>
                    <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                        <DataGrid 
                            rows={rows} 
                            columns={columnas} 
                            rowHeight={30}
                            onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.row) }
                            components={{ Toolbar: QuickSearchToolbar }}
                            componentsProps={{
                              toolbar: {
                                value: searchText,
                                onChange: (event) => requestSearch(event.target.value),
                                clearSearch: () => requestSearch(''),
                              },
                          }}

                        />
                    </div>
                    
                    {/* <Typography variant='caption' style={{marginTop: 10, marginLeft: 16}}>NOTA: Ordenado por fecha de entrada de gasto decreciente</Typography> */}

                </>
                
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
