import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../components/Loading';

import EmptyComponent from '../../../components/EmptyContent';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@material-ui/core';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import { getAlumnosRegistradosBuscar } from '../../../services/alumnos';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Stack } from '@mui/material';
import { visionCuotas } from '../../../services/cuotas';
import Moment from 'react-moment';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
}));

export default function VisionCuotasContent() {
    const classes = useStyles();
    const [cuotas, setCuotas] = useState([])
    const [loading, setLoading] = useState(false)

    let history = useHistory();

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    useEffect(() => {
      setLoading(true)
      visionCuotas({usuario_id: usuarioSeleccionado.id}).then( (response) => {

        setLoading(false)
        response.cuotas && setCuotas(response.cuotas)
        
      })
    
    }, [])
    
    
    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    
    const MostrarFecha = ({fecha}) => (fecha !== '0000-00-00 00:00:00') ? <Moment format="DD/MM/YYYY">{fecha}</Moment> : <>-</>
    const Contenido = () => {

      let alumno_anterior_id = 0
      let cambio_color_fondo = 1
        return (
          <>
            <TituloPagina titulo={"Visión de cuotas"} />
          
            

            <Grid container spacing={2} style={{paddingLeft: 15, paddingRight: 15}}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Alumno</TableCell>
                    <TableCell>Producto</TableCell>
                    <TableCell>Cuota</TableCell>
                    <TableCell align="right">Importe</TableCell>
                    <TableCell align="right">Fecha cuota</TableCell>
                    <TableCell align="right">Fecha envio</TableCell>
                    <TableCell align="right">Fecha cobro</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  
                  { 
                  
                  loading && 
                  
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell colSpan={10} style={{textAlign: 'center'}}><LoadingComponent /> </TableCell>
                  </TableRow>
                  
                  }

                  {cuotas.map((row, index) => {
                    
                    if(alumno_anterior_id != row.alumno_id) {
                      if( cambio_color_fondo == 1 ) {
                        cambio_color_fondo = 2;
                      }else{
                        cambio_color_fondo = 1;
                      }
                      
                      alumno_anterior_id = row.alumno_id;
                    }
                    
                    return (

                      <TableRow
                        key={row.cuota_id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        
                      >
                        <TableCell style={{backgroundColor: cambio_color_fondo === 1 ? '#f1f5f9' : '#e2e8f0'}} component="th" scope="row">{index + 1}</TableCell>
                        <TableCell style={{backgroundColor: cambio_color_fondo === 1 ? '#f1f5f9' : '#e2e8f0'}} scope="row">{row.alumno_id}</TableCell>
                        <TableCell style={{backgroundColor: cambio_color_fondo === 1 ? '#f1f5f9' : '#e2e8f0'}}>
                          <Link href={`/alumnos/detalle/${row.alumno_id}`}>{row.alumno_nombre}</Link>
                        </TableCell>
                        <TableCell style={{backgroundColor: cambio_color_fondo === 1 ? '#f1f5f9' : '#e2e8f0'}}>[{row.alumno_producto_id}] {row.producto_nombre}</TableCell>
                        <TableCell style={{backgroundColor: cambio_color_fondo === 1 ? '#f1f5f9' : '#e2e8f0'}}>{row.cuota_id}</TableCell>
                        <TableCell style={{backgroundColor: cambio_color_fondo === 1 ? '#f1f5f9' : '#e2e8f0'}} align="right">{row.importe}€</TableCell>
                        <TableCell style={{backgroundColor: cambio_color_fondo === 1 ? '#f1f5f9' : '#e2e8f0'}} align="right"><MostrarFecha fecha={row.fecha_cuota}/></TableCell>
                        <TableCell style={{backgroundColor: cambio_color_fondo === 1 ? '#f1f5f9' : '#e2e8f0'}} align="right"><MostrarFecha fecha={row.fecha_envio}/></TableCell>
                        <TableCell style={{backgroundColor: cambio_color_fondo === 1 ? '#f1f5f9' : '#e2e8f0'}} align="right"><MostrarFecha fecha={row.fecha_cobro}/></TableCell>
                        <TableCell style={{backgroundColor: cambio_color_fondo === 1 ? '#f1f5f9' : '#e2e8f0'}} align="center"> {row.es_cuota_inicial === "1" &&<Chip size='small' style={{backgroundColor: '#6fbf73', color: 'white'}} label="Primera cuota" />}</TableCell>
                      </TableRow>
                    )

                  })
                  }
                  
                </TableBody>
              </Table>
            </TableContainer>              
            </Grid>
          </>
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                    <Contenido />
            }
        </div>
    )
}
