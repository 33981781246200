import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
import { dateToMysql } from '../utils/dateTime';

/* -------------------------------------------------------------------------- */
/*                               Get Alumnos                              */
/* -------------------------------------------------------------------------- */
export async function getIndicadoresHome({ usuario_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);

        const response = await axios({
            method: 'post',
            url: BASE_URL_SERVICE + 'alumno/indicadores_home',
            data: formData,
            headers: {
            //  'Content-Type': 'multipart/form-data',
            //  'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            //  'X-AUTH-TOKEN': "1231231231231231"
            },
     //   crossorigin: true,    
     //   mode: 'no-cors',      
         });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }
}
