import { Box, Divider, Typography } from "@material-ui/core"

const TituloPagina = ({titulo}) => {
    return (
        <>
        <Box mt={12} mb={3} mx={2}>
            {/* <Typography gutterBottom style={{ fontWeight: 500, marginTop: 32 }} variant="h6"> */}

            <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >
                {titulo}
            </Typography>
        </Box>
        </>
    )
}

export default TituloPagina