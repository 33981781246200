import axios from 'axios';
import {
    BASE_URL_SERVICE,
} from '../constants'
import { dateToMysql } from '../utils/dateTime';

/* -------------------------------------------------------------------------- */
/*                               Get Alumnos                              */
/* -------------------------------------------------------------------------- */
export async function getAlumnosProductos({ usuario_id, alumno_producto_id }) {
    try {
        var config = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'text/json',
                'Authorization': "04871209347109238740"
            }
        };

        const response = await axios.get(BASE_URL_SERVICE + 'alumno_producto', {
            params: {
                usuario_id,
                alumno_producto_id
            }
        }, config);

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

/* -------------------------------------------------------------------------- */
/*                            Insertar producto                               */
/* -------------------------------------------------------------------------- */
export async function insert_producto({ usuario_id, alumno_id, producto_id, num_cuotas, importe_cuota, fecha_inicio, forma_pago_id }) {
    try {
        let formData = new FormData();

        formData.append("usuario_id", usuario_id);
        formData.append("alumno_id", alumno_id);
        formData.append("producto_id", producto_id);
        formData.append("num_cuotas", num_cuotas);
        formData.append("importe_cuota", importe_cuota);
        formData.append("fecha_inicio", dateToMysql(fecha_inicio));
        formData.append("forma_pago_id", forma_pago_id);

        const response = await axios({
           method: 'post',
           url: BASE_URL_SERVICE + 'alumno/insertar_alumno_producto',
           data: formData,
           headers: {
            // 'Content-Type': 'multipart/form-data',
            // 'Authorization': "Bearer eyJ0eciOiJSUzI1NiJ9.eyJMiIsInNjb3BlcyI6W119.K3lW1STQhMdxfAxn00E4WWFA3uN3iIA", 
            // 'X-AUTH-TOKEN': "1231231231231231"
           },
    //   crossorigin: true,    
    //   mode: 'no-cors',      
        });

        return response.data;
    } catch (error) {
        return undefined;
        // throw error;
    }

}

