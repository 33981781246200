import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import BuscarAlumnoContent from './components/BuscarAlumnoContent';

const BuscarAlumno = () => {

    return (
        <>

            <TemplateDashboard>
                <BuscarAlumnoContent />
            </TemplateDashboard>

        </>
    )
};

export default BuscarAlumno;