import Login from './pages/login';
import Logout from './pages/logout';
import Home from './pages/home';

import NotFound from './pages/notFound';

import {
  BrowserRouter,
  Link,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import React from 'react';

import { useSelector } from "react-redux";

import {
  selectUsuarioSeleccionado,
} from "./redux/reducer/loginReducer";
import AlumnosListado from './pages/alumnos/alumnosListado';
import AlumnosForm from './pages/alumnos/alumnosForm/AlumnosForm';
import CuotasListado from './pages/cuotas/cuotasListado';
import AlumnosRegistradosListado from './pages/alumnos/alumnosRegistradosListado';
import AlumnosAsignacionMasivaListado from './pages/alumnos/alumnosListadoAsignacionMasiva';
import FacturasListado from './pages/cuotas/facturas';
import AnaliticaPivotContent from './pages/analitica/pivot/components/AnaliticaPivotContent';
import AnaliticaPivot from './pages/analitica/pivot';
import MSconta from './pages/contabilidad/msconta';
import AvisosListado from './pages/avisos/avisos';
import AlumnosBajaListado from './pages/alumnos/alumnosBajaListado';
import AlumnosRegistradosAndaluciaListado from './pages/alumnos/alumnosRegistradosAndaluciaListado';
import AccesosTuteaListado from './pages/alumnos/accesosTutea';
import BuscarAlumno from './pages/alumnos/buscarAlumno/BuscarAlumno';
import VisionCuotas from './pages/visionCuotas/VisionCuotas';

// import GastosListado from './pages/gastos/gastosListado';
// import GastosAddFactura from './pages/gastos/gastosAddFactura';
// import GastosAddManual from './pages/gastos/gastosAddManual';
// import PropiedadDetalle from './pages/propiedad/detalle';
// import InquilinosListado from './pages/inquilinos/inquilinosListado';
// import InquilinosForm from './pages/inquilinos/inquilinosForm/InquilinosForm';
// import AlquileresListado from './pages/alquileres/alquileresListado';
// import AlquileresForm from './pages/alquileres/alquileresForm/AlquileresForm';
// import EnvioForm from './pages/envio/envioForm/EnvioForm';
// import EnvioRegistrarCobroForm from './pages/envio/envioRegistrarCobroForm/EnvioRegistrarCobroForm';
// import EnviosListado from './pages/envio/enviosListado';
// import EnvioEditForm from './pages/envio/envioEditForm/EnvioEditForm';

/* --------------------------------- Private -------------------------------- */
function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

/* ---------------------------------- Home ---------------------------------- */
const RouteHome = () => {
  const usuarioSeleccionado = useSelector(state => {
    return selectUsuarioSeleccionado(state);
  });

  console.log(usuarioSeleccionado)
  return (
    ( usuarioSeleccionado.perfil_id === "6" ?
      <Route component={AlumnosRegistradosAndaluciaListado} path="/home"></Route>
      :
      <Route component={Home} path="/home"></Route>
    )
  );
}


/* --------------------------------- Sincronizar ---------------------------- */
// const RouteSincronizar = () => {
//   return (
//     <Route component={SincronizarListado} path="/sincronizar"></Route>
//   );
// }
// const RouteGastosAddFactura = () => {
//   return (
//     <Route component={GastosAddFactura} path="/gastos/add_factura"></Route>
//   );
// }
// const RouteGastosAddManual = () => {
//   return (
//     <Route component={GastosAddManual} path="/gastos/add_manual"></Route>
//   );
// }

// /* ------------------------------- Propiedades ------------------------------ */
// const RoutePropiedadDetalle = () => {
//   return (
//     <>
//       <Route component={PropiedadDetalle} path="/propiedad/detalle/:id"></Route>
//     </>
//   );
// }

/* ------------------------------- Cuotas ------------------------------- */
const RouteCuotas = () => {
  return (
    <>
      <Route component={CuotasListado} path="/cuotas"></Route>
    </>
  );
}
/* ------------------------------- Cuotas ------------------------------- */
const RouteAnaliticaPivot = () => {
  return (
    <>
      <Route component={AnaliticaPivot} path="/analitica"></Route>
    </>
  );
}
/* ------------------------------- Contabilidad ------------------------------- */
const RouteMsconta = () => {
  return (
    <>
      <Route component={MSconta} path="/ms_conta"></Route>
    </>
  );
}
/* ------------------------------- Facturas ------------------------------- */
const RouteFacturas = () => {
  return (
    <>
      <Route component={FacturasListado} path="/facturas"></Route>
    </>
  );
}
/* ------------------------------- Avisos ------------------------------- */
const RouteAvisos = () => {
  return (
    <>
      <Route component={AvisosListado} path="/avisos"></Route>
    </>
  );
}
/* ------------------------------- Alumnos ------------------------------- */
const RouteAlumnos = () => {
  return (
    <>
      <Route component={AlumnosListado} path="/alumnos"></Route>
    </>
  );
}
const RouteAccesosTutea = () => {
  return (
    <>
      <Route component={AccesosTuteaListado} path="/accesos_tutea"></Route>
    </>
  );
}
const RouteAlumnosRegistrados = () => {
  return (
    <>
      <Route component={AlumnosRegistradosListado} path="/alumnos_registrados"></Route>
    </>
  );
}
const RouteAlumnosRegistradosAndalucia = () => {
  return (
    <>
      <Route component={AlumnosRegistradosAndaluciaListado} path="/alumnos_registrados_andalucia"></Route>
    </>
  );
}
const RouteAlumnosBaja = () => {
  return (
    <>
      <Route component={AlumnosBajaListado} path="/alumnos_baja"></Route>
    </>
  );
}
const RouteAlumnosAsignacionMasiva = () => {
  return (
    <>
      <Route component={AlumnosAsignacionMasivaListado} path="/alumnos_asignacion_masiva"></Route>
    </>
  );
}
// const RouteInquilinosCreate = () => {
//   return (
//     <>
//       <Route component={InquilinosForm} path="/inquilinos/create"></Route>
//     </>
//   );
// }
const RouteAlumnosDetalle = () => {
  return (
    <>
      <Route component={AlumnosForm} path="/alumnos/detalle/:id"></Route>
    </>
  );
}
const RouteAlumnosCreate = () => {
  return (
    <>
      <Route component={AlumnosForm} path="/alumnos/create"></Route>
    </>
  );
}
const RouteBuscarAlumno = () => {
  return (
    <>
      <Route component={BuscarAlumno} path="/buscar_alumno"></Route>
    </>
  );
}
const RouteVisionCuotas = () => {
  return (
    <>
      <Route component={VisionCuotas} path="/vision_cuotas"></Route>
    </>
  );
}

// /* ------------------------------- Alquileres ------------------------------- */
// const RouteAlquileres = () => {
//   return (
//     <>
//       <Route component={AlquileresListado} path="/alquileres"></Route>
//     </>
//   );
// }
// const RouteAlquileresCreate = () => {
//   return (
//     <>
//       <Route component={AlquileresForm} path="/alquileres/create"></Route>
//     </>
//   );
// }
// const RouteAlquileresDetalle = () => {
//   return (
//     <>
//       <Route component={AlquileresForm} path="/alquileres/detalle/:id"></Route>
//     </>
//   );
// }
// /* --------------------------------- Envios --------------------------------- */
// const RouteEnvios = () => {
//   return (
//     <Route component={EnviosListado} path="/envios"></Route>
//   );
// }
// const RouteEnvioCreate = () => {
//   return (
//     <>
//       <Route component={EnvioForm} path="/envio/create/:id/:seleccionados"></Route>
//     </>
//   );
// }
// const RouteEnvioRegistrarCobro = () => {
//   return (
//     <>
//       <Route component={EnvioRegistrarCobroForm} path="/envio/registrar_cobro/:id"></Route>
//     </>
//   );
// }
// const RouteEnvioEdit = () => {
//   return (
//     <>
//       <Route component={EnvioEditForm} path="/envio/edit/:id"></Route>
//     </>
//   );
// }


export default function App(props) {
  const usuarioSeleccionado = useSelector(state => {
    return selectUsuarioSeleccionado(state);
  });

  return (

    <BrowserRouter>
      <Switch>

        {/* ZONA PUBLICA */}
        <Route component={Login} path="/login"></Route>
        <Route component={Logout} path="/logout"></Route>
        <Route exact path="/">
          <div className="container">
            {
              usuarioSeleccionado ?
                <Redirect to={{ pathname: '/home', state: { from: props.location } }} />
                :
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }
            <Link className="btn btn-secondary btn-lg btn-block" to="/login">Login</Link>
            <Link className="btn btn-secondary btn-lg btn-block" to="/home">Home</Link>
          </div>
        </Route>

        {/* ZONA PRIVADA */}
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/home' component={RouteHome} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos/create' component={RouteAlumnosCreate} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos/detalle/:id' component={RouteAlumnosDetalle} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos' component={RouteAlumnos} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/avisos' component={RouteAvisos} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/accesos_tutea' component={RouteAccesosTutea} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_registrados' component={RouteAlumnosRegistrados} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_registrados_andalucia' component={RouteAlumnosRegistradosAndalucia} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_baja' component={RouteAlumnosBaja} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/alumnos_asignacion_masiva' component={RouteAlumnosAsignacionMasiva} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/cuotas' component={RouteCuotas} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/facturas' component={RouteFacturas} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/analitica' component={RouteAnaliticaPivot} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/ms_conta' component={RouteMsconta} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/buscar_alumno' component={RouteBuscarAlumno} />
        <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/vision_cuotas' component={RouteVisionCuotas} />
        {/* <PrivateRoute authed={(usuarioSeleccionado ? true : false)} path='/envio/edit/:id' component={RouteEnvioEdit} /> */}

        {/* PAGINA NO ENCONTRADA */}
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>

  )
}