import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AlumnosBajaContent from '../alumnosBajaListado/components/AlumnosBajaContent';

const AlumnosBajaListado = () => {

    return (
        <>

            <TemplateDashboard>
                <AlumnosBajaContent />
            </TemplateDashboard>

        </>
    )
};

export default AlumnosBajaListado;