import moment from "moment";

/* -------------------------------------------------------------------------- */
/*                                 formatDate                                 */
/* -------------------------------------------------------------------------- */
export function formatDate(date, time) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

/* -------------------------------------------------------------------------- */
/*                                 formatTime                                 */
/* -------------------------------------------------------------------------- */
export function formatTime(date) {
    var d = new Date(date),
        hours = '' + (d.getHours()),
        minutes = '' + d.getMinutes();

    if (hours.length < 2)
        hours = '0' + hours;
    if (minutes.length < 2)
        minutes = '0' + minutes;

    return [hours, minutes].join(':');
}

/* -------------------------------------------------------------------------- */
/*                               formatDateTime                               */
/* -------------------------------------------------------------------------- */
export function formatDateTime(date, time) {
    return formatDate(date) + " " + formatTime(time);
}

/* -------------------------------------------------------------------------- */
/*                                 dateToMysql                                */
/* -------------------------------------------------------------------------- */
export const dateToMysql = (fecha) => {
    if(fecha !== null && fecha !== undefined && fecha !== '0000-00-00 00:00:00') {
        try{
            return moment(fecha).format('YYYY-MM-DD HH:mm:ss')
        } catch (error) {
            return null;
        }
    } else {
        return null;
    }
}

/* -------------------------------------------------------------------------- */
/*                                 mysqlToDate                                */
/* -------------------------------------------------------------------------- */
export const mysqlToDate = (fecha) => {
    console.log(fecha)
    if(fecha != null && fecha != undefined) {
        try{
            return moment(fecha).format('DD/MM/YYYY')
        } catch (error) {
            return null;
        }
    } else {
        return null;
    }
}