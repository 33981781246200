import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../components/EmptyContent';
import { Box, Button, Card, CardActions, CardContent, Chip, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, alpha } from '@material-ui/core';
import { Link } from 'react-router-dom';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Moment from 'react-moment';
import TituloPagina from '../../../components/Titulos/TituloPagina';
import { Block, Brightness1, NoEncryption } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { DataGrid } from '@mui/x-data-grid';
import { Stack } from '@mui/system';
import { getAlumnos_contactados_esta_semana, getAlumnos_contactados_hoy, getAlumnos_planificados_esta_semana, getAlumnos_planificados_hoy, getAlumnos_planificados_pasado, getAvisosUsuario, getVentasComercial, get_leads_by_comercial, vdw_alumnos_estado } from '../../../services/alumnos';
import { actualizarActidadComercial, getComerciales } from '../../../services/comerciales';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import moment from 'moment';
import BarChartIcon from '@material-ui/icons/BarChart';
import { getIndicadoresHome } from '../../../services/indicadores_home';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    cardRoot: {
        minWidth: 275,
    },
    cardTitle: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    cardPos: {
        marginBottom: 12,
    },

    cardContainer: {
        flexGrow: 1,
        margin: 16,
    },
    paperSeccion: {
        padding: 10
    }
}));

export default function HomeContent() {
    const classes = useStyles();

    const [loading, setLoading] = useState(false)

    const [alumnosPlanificadosHoy, setAlumnosPlanificadosHoy] = useState([])
    const [alumnosPlanificadosHoyTurnoManana, setAlumnosPlanificadosHoyTurnoManana] = useState([])
    const [alumnosPlanificadosHoyTurnoTarde, setAlumnosPlanificadosHoyTurnoTarde] = useState([])
    const [alumnosPlanificadosPasado, setAlumnosPlanificadosPasado] = useState([])
    const [cuotasMuyCalientes, setCuotasMuyCalientes] = useState([])
    const [cuotasRetrasadas, setCuotasRetrasadas] = useState([])
    // const [alumnosContactadosHoy, setAlumnosContactadosHoy] = useState([])

    const [dw, setDw] = useState([])
    const [leadsByComercial, setLeadsByComercial] = useState([])


    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const [ventasComercial, setVentasComercial] = useState([]);

    useEffect(() => {
        setLoading(true)

        const usuario_id = usuarioSeleccionado.id

        actualizarActidadComercial({usuario_id}).then( (response) => {
        })

        getAlumnos_planificados_hoy({usuario_id}).then( (response) => {
            response && setAlumnosPlanificadosHoy(response.alumnos)

            setLoading(false)

        })
        
        getAlumnos_planificados_pasado({usuario_id}).then( (response) => {
            response && setAlumnosPlanificadosPasado(response.alumnos)
            setLoading(false)

        })

        get_leads_by_comercial({usuario_id}).then( (response) => {
            console.log(response.leads)
            response && setLeadsByComercial(response.leads)
            setLoading(false)

        })

        // getAlumnos_planificados_esta_semana({usuario_id}).then( (response) => {
        //     response && setAlumnosPlanificadosSemana(response.alumnos)
        //     setLoading(false)

        // })

        // getAlumnos_contactados_hoy({usuario_id}).then( (response) => {
        //     response && setAlumnosContactadosHoy(response.alumnos)
        //     setLoading(false)

        // })


        vdw_alumnos_estado({usuario_id}).then ( (response) => {
            response && setDw(response.comerciales)
        })

        getAvisosUsuario({usuario_id}).then ( (response) => {
            // response && setDw(response.comerciales)
            // console.log(response)
        })

        getVentasComercial({ usuario_id }).then((response) => {

            response && console.log(response.ventas_comercial)

            response && setVentasComercial(response.ventas_comercial)
        });

        getIndicadoresHome({ usuario_id }).then((response) => {

            response && console.log(response.cuotas_muy_calientes)
            const cuotas_muy_calientes = response.cuotas_muy_calientes
            const cuotas_retrasadas = response.cuotas_retrasadas

            response && setCuotasMuyCalientes(cuotas_muy_calientes.length)
            response && setCuotasRetrasadas(cuotas_retrasadas.length)

        });
    }, [])

    useEffect(() => {
        let alumnosTurnoManana = alumnosPlanificadosHoy && alumnosPlanificadosHoy.filter( (item) => {
            let proximo_contacto = moment(item.proximo_contacto)
            var hora_corte = moment(item.proximo_contacto)
            hora_corte.hours(15)
            hora_corte.minutes(0)

            return proximo_contacto.isBefore(hora_corte)
        })
        setAlumnosPlanificadosHoyTurnoManana(alumnosTurnoManana)
        
        let alumnosTurnoTarde = alumnosPlanificadosHoy && alumnosPlanificadosHoy.filter( (item) => {
            let proximo_contacto = moment(item.proximo_contacto)
            var hora_corte = moment(item.proximo_contacto)
            hora_corte.hours(14)
            hora_corte.minutes(59)

            return proximo_contacto.isAfter(hora_corte)
        })
        setAlumnosPlanificadosHoyTurnoTarde(alumnosTurnoTarde)

    }, [alumnosPlanificadosHoy])

    // const CardPropiedad = ({propiedad}) => {
    //     return (
    //         <Grid item xs={12} sm={6} md={4} lg={3}>
    //             <Card className={classes.cardRoot} variant="outlined">
    //                 <CardContent>
    //                     <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
    //                     {propiedad.propiedad_nombre}
    //                     </Typography>

    //                     { 
    //                         propiedad.alquiler_es_activo === '1' 
                            
    //                         ? 
                            
    //                         <>
    //                             <Typography variant="body2" color="textSecondary" component="p">{propiedad.inquilino_nombre}</Typography>
    //                             <Typography variant="body2" color="textSecondary" component="p"><Moment format='DD/MM/YYYY'>{propiedad.alquiler_fecha_desde}</Moment> - <Moment format='DD/MM/YYYY'>{propiedad.alquiler_fecha_hasta}</Moment></Typography>

    //                             <Grid container direction="column" spacing={2}>

    //                                 <Chip label="Larga Estancia" size="small" style={{ backgroundColor: green[100], marginTop: 10 }}/> 

    //                                 { propiedad.importe_envios_pendientes_cobro != null ?
    //                                     <Chip label={`Envios pendientes cobro: ${propiedad.num_envios_pendientes_cobro} envios - ${(Math.round(propiedad.importe_envios_pendientes_cobro * 100) / 100).toFixed(2) }€`} size="small" style={{backgroundColor: red[100], marginTop: 10 }}/> 
    //                                 :
    //                                     <Chip label={`Envios pendientes cobro: ${propiedad.num_envios_pendientes_cobro} envios`} size="small" style={{ backgroundColor: grey[100], marginTop: 10 }}/> 
    //                                 }

    //                             </Grid>
    //                         </>
                            
    //                         : 
                            
    //                         <>
    //                             <Typography variant="body2" color="textSecondary" component="p">{propiedad.inquilino_nombre ? propiedad.inquilino_nombre : <>&nbsp;</>}</Typography>
    //                             <Typography variant="body2" color="textSecondary" component="p">
    //                                 { 
    //                                 propiedad.alquiler_fecha_desde 
    //                                 ?
    //                                     <><Moment format='DD/MM/YYYY'>{propiedad.alquiler_fecha_desde}</Moment> - <Moment format='DD/MM/YYYY'>{propiedad.alquiler_fecha_hasta}</Moment></>
    //                                 :
    //                                 <>&nbsp;</>
    //                                 }
    //                             </Typography>
    //                             <Grid container direction="column" spacing={2}>
    //                             <Chip label="Vacio" size="small"  style={{ backgroundColor: red[100], marginTop: 10 }}/>
    //                              { propiedad.importe_envios_pendientes_cobro != null ?
    //                                     <Chip label={`Envios pendientes cobro: ${propiedad.num_envios_pendientes_cobro} envios - ${(Math.round(propiedad.importe_envios_pendientes_cobro * 100) / 100).toFixed(2) }€`} size="small" style={{backgroundColor: red[100], marginTop: 10 }}/> 
    //                                 :
    //                                     <Chip label={`Envios pendientes cobro: ${propiedad.num_envios_pendientes_cobro} envios`} size="small" style={{ backgroundColor: grey[100], marginTop: 10 }}/> 
    //                                 }
    //                             </Grid>
                                
    //                         </>
    //                     }

    //                 </CardContent>

    //                 <CardActions style={{backgroundColor: '#c8c8c82e'}}>
    //                     <Button size="small" color='primary' component={Link} to={`propiedad/detalle/${propiedad.id}`}>Ver detalles</Button>
    //                 </CardActions>
    //             </Card>
    //         </Grid>
    //     )
    // }

    const StackAlumnos = ({arrayAlumnos}) => {
        return (
        
        <Stack direction="column" spacing={2} style={{marginTop: 10}}>
            {
                arrayAlumnos.map( (item) => {
                    return (
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    {item.nombre}
                                </Typography>

                                <Typography variant="body2" component="p">
                                    {item.especialidad_nombre}
                                    <br />
                                    {item.comunidad_autonoma_nombre}
                                    <br />
                                    {item.status_nombre}
                                    <br />
                                    {item.temperatura_nombre}
                                    <br />
                                    <br />
                                    Ultimo contacto: {item.ultimo_contacto}
                                    <br />
                                    Proximo contacto: {item.proximo_contacto}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button 
                                    size="small"
                                    component={Link}
                                    to={`alumnos/detalle/${item.id}`}
                                >Abrir ficha</Button>
                            </CardActions>
                        </Card>
                    )
                })
            }
        </Stack>

        )
    }
    const ContenidoComercial = () => {
        return (<>
        
            <div className={classes.cardContainer}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{marginTop: 20, marginBottom: 20}}>
                        <Typography variant="h4" gutterBottom>{`Hola ${usuarioSeleccionado.usuario_nombre}`}</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={classes.paperSeccion}>
                        <Typography variant="h6" gutterBottom>Planificados pendientes <Chip size='small' label={alumnosPlanificadosPasado.length} color="primary"></Chip></Typography>
                        <Divider />

                            <StackAlumnos arrayAlumnos={alumnosPlanificadosPasado} />
                        
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={classes.paperSeccion}>
                        <Typography variant="h6" gutterBottom>Planificados para hoy (Por la mañana)  <Chip size='small' label={alumnosPlanificadosHoyTurnoManana.length} color="primary"></Chip></Typography>
                        <Divider />

                            <StackAlumnos arrayAlumnos={alumnosPlanificadosHoyTurnoManana} />
                        
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={classes.paperSeccion}>
                        <Typography variant="h6" gutterBottom>Planificados para hoy (Por la tarde)  <Chip size='small' label={alumnosPlanificadosHoyTurnoTarde.length} color="primary"></Chip></Typography>
                        <Divider />

                            <StackAlumnos arrayAlumnos={alumnosPlanificadosHoyTurnoTarde} />
                        
                        </Paper>
                    </Grid>
                    {/* <Grid item xs={3}>
                        <Paper className={classes.paperSeccion}>
                            <Typography variant="h6" gutterBottom>Planificados esta semana <Chip size='small' label={alumnosPlanificadosSemana.length} color="primary"></Chip></Typography>
                            <Divider />

                            <StackAlumnos arrayAlumnos={alumnosPlanificadosSemana} />
                            
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <Paper className={classes.paperSeccion}>
                            <Typography variant="h6" gutterBottom>Contactados hoy  <Chip size='small' label={alumnosContactadosHoy.length} color="primary"></Chip></Typography>
                            <Divider />

                            <StackAlumnos arrayAlumnos={alumnosContactadosHoy} />

                        </Paper>
                    </Grid> */}
                </Grid>
            </div>
        </>)
    }

    // const TablaComercial = ({comercial}) => {
    //     return (
    //         <>
    //             <div className={classes.cardContainer}>
    //                 <Typography variant='h6'>{comercial.nombre}</Typography>    

    //                 <TableContainer component={Paper}>
    //                     <Table className={classes.table} aria-label="simple table">
    //                         <TableHead>
    //                             <TableRow>
    //                                 <TableCell>Fecha</TableCell>
    //                                 <TableCell align="right">Lead</TableCell>
    //                                 <TableCell align="right">Contactado</TableCell>
    //                                 <TableCell align="right">En progreso</TableCell>
    //                                 <TableCell align="right">Stand by</TableCell>
    //                                 <TableCell align="right">No contesta</TableCell>
    //                                 <TableCell align="right">Septiembre</TableCell>
    //                                 <TableCell align="right">Alumno</TableCell>
    //                                 <TableCell align="right">Nada que ofrecer</TableCell>
    //                                 <TableCell align="right">Perdida</TableCell>
    //                                 <TableCell align="right">Pendiente de pago</TableCell>
    //                             </TableRow>
    //                         </TableHead>
    //                         <TableBody>
    //                             {comercial.dw.map( (fila) => {
    //                                 return (
    //                                     <TableRow key={fila.fecha}>
    //                                         <TableCell component="th" scope="row">
    //                                             {fila.fecha}
    //                                         </TableCell>
    //                                         <TableCell align="right">{fila.e88}</TableCell>
    //                                         <TableCell align="right">{fila.e89}</TableCell>
    //                                         <TableCell align="right">{fila.e90}</TableCell>
    //                                         <TableCell align="right">{fila.e91}</TableCell>
    //                                         <TableCell align="right">{fila.e92}</TableCell>
    //                                         <TableCell align="right">{fila.e93}</TableCell>
    //                                         <TableCell align="right">{fila.e94}</TableCell>
    //                                         <TableCell align="right">{fila.e95}</TableCell>
    //                                         <TableCell align="right">{fila.e96}</TableCell>
    //                                         <TableCell align="right">{fila.e97}</TableCell>
    //                                     </TableRow>
    //                                 )
    //                             })}
    //                         </TableBody>
    //                     </Table>
    //                 </TableContainer>
    //             </div>
    //         </>
    //     )
    // }
    
    const TablaLeadsByComercial = () => {
        return (
            <>
                <div className={classes.cardContainer}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="">Comercial</TableCell>
                                    <TableCell align="right">Leads validos</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leadsByComercial.map( (fila) => {
                                    return (
                                        fila.num_leads_validos > 0 && <TableRow key={fila.fecha}>
                                            <TableCell component="th" scope="row">
                                                {fila.comercial_nombre}
                                            </TableCell>
                                            <TableCell align="right">{fila.num_leads_validos}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ width: '100%', padding:"10px 0" }}>
                        <b>Nota: </b> Solo aparecen los comerciales que tienen algún lead válido
                    </div>
                </div>
            </>
        )
    }

    class PivotTableUISmartWrapper extends React.PureComponent {
        constructor(props) {
          super(props);
          this.state = props;
          this.ventasComercial = props.ventasComercial
        }
      
        render() {
          return (
            <>
                  
                <div style={{ width: '100%', padding:"0 15px" }}>
                    <PivotTableUI
                        data={this.ventasComercial}
                        onChange={s => this.setState(s)}
                        rows={["comercial_nombre"]}
                        cols={["year", "mes", "semana"]}
                        {...this.state}
                    />
                </div>
                <div style={{ width: '100%', padding:"5px 15px" }}>
                    <b>Nota: </b> Los datos mostrados está calculados en base a la primera cuota del alumno
                </div>
                <br/>
                <br/>
                
              </>
          );
      }
    }
      
    const CardIndicador = ({titulo, valor, descripcion}) => {
        return (
            <Card elevation={5}>
                <CardContent>

                    <Grid container justify="space-between">
                        <Grid item style={{display: 'flex', width: '100%'}}>
                            <Box
                                sx={{
                                width: 60,
                                height: 60,
                                marginRight: 2,
                                display: "flex",
                                borderRadius: "50%",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: '#0B314E'
                                // backgroundColor: alpha(C, 0.2),
                                }}
                            >
                                <BarChartIcon fontSize='large' style={{color: "#ffffff"}} />
                            </Box>
                            <Box style={{
                                backgroundColor: red,
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                alignItems: 'flex-end'
                            }}>
                                <Typography color="textSecondary" variant="body2">
                                    {titulo}
                                </Typography>
                                <Typography variant="h4">
                                    {valor}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid style={{paddingTop: '20px'}}>
                            <Typography color="textSecondary" variant="body2">
                                {descripcion}
                            </Typography>
                        </Grid>
                    </Grid>
                
                </CardContent>
            </Card>
        )
    }

    const ContenidoIndicadores = () => {
        return (
            <div style={{ width: '100%', padding:"0 15px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <CardIndicador titulo="CUOTAS MUY CALIENTES" valor={cuotasMuyCalientes} descripcion={"Primera cuota y no es pago recurrente"}/>
                    </Grid>
                    <Grid item xs={3}>
                        <CardIndicador titulo="CUOTAS RETRASADAS" valor={cuotasRetrasadas} descripcion={"No es primera cuota y no es pago recurrente"}/>
                    </Grid>
                    <Grid item xs={3}>
                        <CardIndicador titulo="PENDIENTE DEFINIR" valor={0} descripcion={"Descripción"}/>
                    </Grid>
                    <Grid item xs={3}>
                        <CardIndicador titulo="PENDIENTE DEFINIR" valor={0}  descripcion={"Descripción"}/>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const ContenidoAdministrador = () => {
        return (
            <>
                <ContenidoIndicadores />

                <TituloPagina titulo={"Leads válidos por comercial"} />
                <TablaLeadsByComercial />

                <TituloPagina titulo={"Ventas por comercial"} />
                <PivotTableUISmartWrapper ventasComercial={ventasComercial} />
            </>
        )
        // return (
        //     <TableContainer component={Paper}>
        //         <Table className={classes.table} aria-label="simple table">
        //             <TableHead>
        //             <TableRow>
        //                 <TableCell>Dessert (100g serving)</TableCell>
        //                 <TableCell align="right">Calories</TableCell>
        //                 <TableCell align="right">Fat&nbsp;(g)</TableCell>
        //                 <TableCell align="right">Carbs&nbsp;(g)</TableCell>
        //                 <TableCell align="right">Protein&nbsp;(g)</TableCell>
        //             </TableRow>
        //             </TableHead>
        //             <TableBody>
        //             {rows.map((row) => (
        //                 <TableRow key={row.name}>
        //                 <TableCell component="th" scope="row">
        //                     {row.name}
        //                 </TableCell>
        //                 <TableCell align="right">{row.calories}</TableCell>
        //                 <TableCell align="right">{row.fat}</TableCell>
        //                 <TableCell align="right">{row.carbs}</TableCell>
        //                 <TableCell align="right">{row.protein}</TableCell>
        //                 </TableRow>
        //             ))}
        //             </TableBody>
        //         </Table>
        //     </TableContainer>
        // )
    }
    const Contenido = () => {
        return (
            usuarioSeleccionado.perfil_id === "1" ?
            <ContenidoAdministrador />
            :
            <ContenidoComercial />
        )
        // return (
        //     propiedades ?
        //         <>

        //             <TituloPagina titulo={"CRM"} />
        //             <div className={classes.cardContainer}>
        //                 <Grid container spacing={3}>
        //                     {
        //                         propiedades && propiedades.map( (item) => {
        //                             return <CardPropiedad key={item.id} propiedad={item}/>
        //                         } )
        //                     }
        //                 </Grid>
        //             </div>
        //         </>
        //         :
        //         <EmptyComponent />
        // )
    }
    
    return (
        <div className={classes.container}>
            {
                (
                    loading ?
                    
                    <LoadingComponent isFullScreen={true} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
