import { Box, Button, Divider, makeStyles, Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import LoadingComponent from "../Loading";
import TituloForm from "../Titulos/TituloForm";

const useStyles = makeStyles((theme) => ({
    formulario: {
        // margin: 8
    },
}));

const TemplateForm = (props) => {
    const {
        tituloForm, 
        onSubmit, 
        loading, 
        textoBtnGuardar = "Guardar", 
        marginY = 12,
    } = props

    const classes = useStyles();
    let history = useHistory();

    return (
    <>
        <Box sx={{marginX: 2, marginY: marginY}}>
            <Paper style={{padding: 20}}>
                <TituloForm titulo={tituloForm} />

                <form className={classes.formulario} noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={onSubmit}>

                    {props.children}

                    <Box mt={5}>
                        <Divider />
                        <Box mt={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        
                            <Button color="primary" style={{ marginRight: '30px' }} onClick={() => history.goBack()}>Cancelar</Button>

                            { 
                                
                                loading ?
                                
                                <LoadingComponent />

                                :


                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    type="submit"
                                >
                                    {textoBtnGuardar}
                                </Button>

                            }
                            
                        </Box>
                    </Box>   
                    
                </form>
            </Paper>
        </Box>
    </>
    )
}

export default TemplateForm