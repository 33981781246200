import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles, withStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import { AppBar, Box, Divider, Tab, Tabs, Typography } from '@material-ui/core';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import CuotasAtrasadasPendientesContent from './CuotasAtrasadasPendientesContent';
import CuotasMesActualPendientesContent from './CuotasMesActualPendientesContent';
import CuotasMesActualCobradoContent from './CuotasMesActualCobradoContent';
import CuotasPrevisionMesSiguienteContent from './CuotasPrevisionMesSiguienteContent';
import CuotasEvolucionContent from './CuotasEvolucionContent';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function CuotasContent() {
    const classes = useStyles();

    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")
    const [tabSelected, setTabSelected] = React.useState(0);

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        const usuario_id = usuarioSeleccionado.id
    }, [])
    
    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */
    const handleAlumnoDetalle = (row) => {
      history.push('alumnos/detalle/' + row.alumno_id )
    }
    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function TabPanel(props) {
      const { children, value, index, ...other } = props;
    
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }
    const CustomFooterTotalComponent = (props) => {
      const euroFormat = Intl.NumberFormat("es-ES")
      return (
        <Box sx={{ paddingX: "10px", paddingY: "20px", display: "flex", backgroundColor: '#f0f0f0' }}><b> [{props.numCuotas} registros] {props.title} : {euroFormat.format(props.total)}€</b></Box>
      );
    }
    const Contenido = () => {
      const handleChangeTabSelected = (event, newValue) => {
        setTabSelected(newValue);
      };
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
      
      return (
        <>
        <TituloPagina titulo={"Cuotas"} />
        <Box mb={3} mx={2}>
        <AppBar position="static">
          <Tabs value={tabSelected} onChange={handleChangeTabSelected} aria-label="simple tabs example">
            { (usuarioSeleccionado.perfil_id === "1" || usuarioSeleccionado.perfil_id === "2" || usuarioSeleccionado.perfil_id === "5") && <Tab label="Cuotas atrasadas pendientes" {...a11yProps(0)} /> }
            { (usuarioSeleccionado.perfil_id === "1" || usuarioSeleccionado.perfil_id === "2" || usuarioSeleccionado.perfil_id === "5") && <Tab label="Cuotas mes actual pendientes" {...a11yProps(1)} /> }
            { (usuarioSeleccionado.perfil_id === "1") && <Tab label="Cuotas mes actual cobrado" {...a11yProps(2)} /> }
            { (usuarioSeleccionado.perfil_id === "1") && <Tab label="Previsión mes siguiente" {...a11yProps(3)} /> }
            { (usuarioSeleccionado.perfil_id === "1") && <Tab label="Evolución de cuotas" {...a11yProps(4)} /> }
          </Tabs>
        </AppBar>
        <TabPanel value={tabSelected} index={0}>
          <Box mx={2}>
            <Typography variant='h6'>Cuotas atrasadas pendientes </Typography>
            <Typography variant='paragrph'>Todas las cuotas de meses anteriores que a día de hoy siguen sin estar cobradas</Typography>
            <Divider />
          </Box>

          <CuotasAtrasadasPendientesContent />

          <Box mx={2} mt={10}>
            <Typography variant='caption' style={{marginTop: 10, marginLeft: 16}}>NOTA: Ordenado por fecha de entrada de gasto decreciente</Typography>
          </Box>
        </TabPanel>
        <TabPanel value={tabSelected} index={1}>
          <Box mx={2}>
            <Typography variant='h6'>Cuotas mes actual pendientes </Typography>
            <Typography variant='paragrph'>Todas las cuotas del mes actual que aún no están cobradas</Typography>
            <Divider />
          </Box>

          {/* <Box mx={2} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
            <a style={{color:'red'}} href="https://gestion.innovatiio.com/api/procesos/ppr" target="_blank" rel='noreferrer'>Forzar pagos recurrentes ahora</a>
          </Box> */}
          <CuotasMesActualPendientesContent />

          <Box mx={2} mt={10}>
            <Typography variant='caption' style={{marginTop: 10, marginLeft: 16}}>NOTA: Ordenado por fecha de entrada de gasto decreciente</Typography>
          </Box>
        </TabPanel>
        <TabPanel value={tabSelected} index={2}>
          <Box mx={2}>
            <Typography variant='h6'>Cuotas mes actual cobrado</Typography>
            <Typography variant='paragrph'>Todas las cuotas cobradas este mes </Typography>
            <Divider />
          </Box>

          <CuotasMesActualCobradoContent />

          <Box mx={2} mt={10}>
            <Typography variant='caption' style={{marginTop: 10, marginLeft: 16}}>NOTA: Ordenado por fecha de entrada de gasto decreciente</Typography>
          </Box>
        </TabPanel>
        <TabPanel value={tabSelected} index={3}>
          <Box mx={2}>
            <Typography variant='h6'>Previsión mes siguiente</Typography>
            <Typography variant='paragrph'>Todas las cuotas que se tienen que cobrar el mes siguiente </Typography>
            <Divider />
          </Box>

          <CuotasPrevisionMesSiguienteContent />

          <Box mx={2} mt={10}>
            <Typography variant='caption' style={{marginTop: 10, marginLeft: 16}}>NOTA: Ordenado por fecha de entrada de gasto decreciente</Typography>
          </Box>
        </TabPanel>
        <TabPanel value={tabSelected} index={4}>
          <Box mx={2}>
            <Typography variant='h6'>Evolución de cuotas </Typography>
            <Typography variant='paragrph'>Previsión de cuotas por mes</Typography>
            <Divider />
          </Box>
          <CuotasEvolucionContent />
        </TabPanel>
        </Box>
          
          
        </>
              
      )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
