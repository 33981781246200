import React from "react";

import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Box,
    Typography,
    Container,
} from '@material-ui/core';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

import InputText from "../../components/InputText";

import useLogin from "../../hooks/useLogin";

import "./Login.css";

import {
    Redirect
} from 'react-router-dom';

import logo from '../../assets/img/logo-primary-color.png'
import logos_camara from '../../assets/img/logos_camara.png'

const useStyles = makeStyles((theme) => ({
    contenedor: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        justifyContent: 'center'
    },
    paper: {
        // marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://emociom.com/">
                Innovatiio.com
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Login = () => {
    const [setEmail,
        emailEsValido,
        emailMessage,
        passwordEsValido,
        setPassword,
        setEnviar,
        error,
        usuarioSeleccionado,
    ] = useLogin();

    function handleSubmit(event) {
        // alert("Se puede enviar: " + (emailEsValido && passwordEsValido));

        (emailEsValido && passwordEsValido) && setEnviar(true);

        event.preventDefault();
    }

    const classes = useStyles();

    return (
        <>
            {
                usuarioSeleccionado

                    ?

                    <Redirect to="/home" />

                    :

                    <Container component="main" maxWidth="xs">

                        {(

                            // https://fkhadra.github.io/react-toastify
                            <ToastContainer closeButton={false} />


                        )}

                        <CssBaseline />
                        <div className={classes.contenedor}>
                            <div className={classes.paper}>
                                <Box mb={10}>
                                    <img width="300px" src={logo} alt='Innovatiio' />
                                </Box>
                                {/* <Avatar className={classes.avatar}>
                                    <LockOutlinedIcon />
                                </Avatar> */}
                                {/* <Typography component="h1" variant="h5">
                                    Inicio de sesión
                                </Typography> */}
                                <form className={classes.form} noValidate>
                                    <InputText
                                        required
                                        fullWidth
                                        id="email"
                                        label="Correo electrónico"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        onChange={(e) => setEmail(e.target.value)}
                                        esValido={emailEsValido}
                                        errorMessage={emailMessage}
                                    />

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Contraseña"
                                        type="password"
                                        id="password"
                                        autoComplete="current-password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    {/* <FormControlLabel
                                        control={<Checkbox value="remember" color="primary" />}
                                        label="Recuérdame"
                                    /> */}
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={handleSubmit}
                                    >
                                        Entrar
                                    </Button>
                                    {/* <Grid container>
                                        <Grid item xs>
                                            <Link href="#" variant="body2">
                                                ¿Olvidaste la contraseña?
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link href="#" variant="body2">
                                                {"No tienes cuenta? Regístrate"}
                                            </Link>
                                        </Grid>
                                    </Grid> */}
                                </form>
                            </div>
                            <Box mt={8}>
                                <Copyright />
                            </Box>
                            <Box mt={8}>
                                <img style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}} src={logos_camara} alt='Innovatiio' />
                            </Box>
                        </div>
                        
                    </Container>
                    
            }
        </>
    )
};

export default Login;