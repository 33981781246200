import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles, withStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Link, useHistory } from 'react-router-dom';
// import Moment from 'react-moment';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import { getAlumnos, getAlumnosPivot } from '../../../../services/alumnos';
import TituloPagina from '../../../../components/Titulos/TituloPagina';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function AnaliticaPivotContent() {
    const classes = useStyles();

    // const [facturas, setFacturas] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    // const history = useHistory()
    const [alumnos, setAlumnos] = useState([]);

    // const data = [['attribute', 'attribute2'], ['value1', 'value2']];

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando facturas")

        const usuario_id = usuarioSeleccionado.id

        getAlumnosPivot({ usuario_id }).then((response) => {

            response && setAlumnos(response.alumnos)
            setLoading(false)
        });

    }, [])

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */

    const Contenido = () => {

        return (
                <>
                  <TituloPagina titulo={"Analítica"} />
                  <div style={{ width: '100%', padding:"0 15px" }}>
                  <PivotTableUISmartWrapper alumnos={alumnos} />
                  </div>
                </>
                
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
// const data = [['attribute', 'attribute2'], ['value1', 'value2']];

class PivotTableUISmartWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = props;
    this.alumnos = props.alumnos
  }

  render() {
    return (
        <PivotTableUI
            data={this.alumnos}
            onChange={s => this.setState(s)}
            rows={["comunidad_autonoma_nombre", "oposicion_nombre", "especialidad_nombre"]}
            {...this.state}
        />
    );
}
}
