import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { DataGridPro, GridToolbar, esES, GridRowParams } from "@mui/x-data-grid-pro"
import Moment from "react-moment";
// import { cachedDataVersionTag } from "v8";
import './styles.css'

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';


const DataGridInnovattio = ({ height, rows, columns, loading, checkboxSelection, onSelectionModelChange, onDoubleClick, getRowClassName, customFooter, ...props }) => {
  const [platform, setPlatform] = useState(rows);
  const [rowsInterior, setRowsInterior] = useState(rows);
  const [searchText, setSearchText] = useState('');

  useEffect( () => { 
    setPlatform(rows) 
    setRowsInterior(rows)
  }, [rows]);

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }
  const requestSearch = (searchValue) => {
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = platform.filter((row) => {
          return Object.keys(row).some((field) => {
              return row[field] && searchRegex.test(row[field].toString());
          });
      });
      setRowsInterior(filteredRows);
  };

  const customComponent = customFooter ? { Toolbar: GridToolbar, Footer: customFooter } : { Toolbar: GridToolbar }

    return (
      <>
        <div style={{ display: 'flex', height: 700 }}>
          <div style={{ flexGrow: 1 }}>
            {/* <div style={{ height: height ? height : 600, width: "100%" }}> */}
              <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <TextField
                        variant="standard"
                        value={searchText}
                        onChange={(e) => { setSearchText(e.target.value); requestSearch(e.target.value) }}
                        placeholder="Buscar..."
                        InputProps={{
                            startAdornment: <SearchIcon fontSize="small" color="action" />,
                            endAdornment: (
                                <IconButton
                                    title="Clear"
                                    aria-label="Clear"
                                    size="small"
                                    style={{ visibility: searchText ? 'visible' : 'hidden', borderRadius: "57%", paddingRight: "1px", margin: "0", fontSize: "1.25rem" }}
                                    onClick={(e) => {setSearchText(''); setRowsInterior(platform)} }
                                >
                                    <ClearIcon fontSize="small" color="action" />
                                </IconButton>
                            ),
                        }}
                        sx={{
                            width: { xs: 1, sm: 'auto' }, m: (theme) => theme.spacing(1, 0.5, 1.5),
                            '& .MuiSvgIcon-root': {
                                mr: 0.5,
                            },
                            '& .MuiInput-underline:before': {
                                borderBottom: 1,
                                borderColor: 'divider',
                            },
                        }}
                    />
                </Box>
                { 
                  columns && 
                  <DataGridPro  
                    checkboxSelection={checkboxSelection}
                    rows={rowsInterior} 
                    columns={columns} 
                    loading={loading}
                    rowHeight={40} 
                    disableDensitySelector
                    onSelectionModelChange={itm => onSelectionModelChange(itm)}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText} 
                    onRowDoubleClick={ (row, dataIndex) => {
                      onDoubleClick(row.row)
                    }} 
                    getRowClassName = { (params) => {
                      if(getRowClassName !== undefined) {
                        return getRowClassName(params)
                      }
                      return ""
                      
                    }}
                    {...props}
                    // onStateChange = {onStateChange}
                    components={customComponent} 
                  /> 
                }
            {/* </div> */}
          </div>
        </div>
      </>
    )
}

export default DataGridInnovattio

export const renderCellDate = (value) =>{
  return (
      
        (value !== null) && value !== '0000-00-00 00:00:00' ?
  
        <Moment format="DD/MM/YYYY">
          {value}
        </Moment>

        :

        <></>

      )
}

