import { AppBar, Box, Button, ButtonGroup, Chip, Divider, FormControl, FormHelperText, Grid, IconButton, Input, InputLabel, makeStyles, Menu, MenuItem, Paper, Select, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { useFormik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import * as yup from 'yup';
// import { addFactura } from '../../../services/inquilino';
import TemplateForm from '../../../components/TemplateForm/TemplateForm';
import { getAlumno, getAlumnosByCorreoElectronico, insertar_alumno, update_alumno } from '../../../services/alumnos';
import SwipeableViews from 'react-swipeable-views';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getListaDetalle } from '../../../services/lista_detalle';
import { getComerciales } from '../../../services/comerciales';
import { getAlumnosNotas } from '../../../services/alumnos_notas';
import AlumnosNotasForm from './AlumnosNotasForm';
import AlumnosProductoForm from './AlumnosProductoForm';
import Swal from 'sweetalert2';
import moment from 'moment';
import Moment from 'react-moment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import esLocale from 'date-fns/locale/es';
import AlumnoStatus from './AlumnoStatus';
import { getTutores } from '../../../services/tutores';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import { Stack } from '@mui/material';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 90
        // display: 'flex',
        // flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    keyboardDatePicker: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    formulario: {
        margin: 8
    },
    input: {
        display: 'none'
    },
    chipCancelado: {
        backgroundColor: red[100]
    }
}));

/* -------------------------------------------------------------------------- */
/*                            Esquema de valicación                           */
/* -------------------------------------------------------------------------- */
const validationSchema = yup.object({

    nombre: yup.string().required("Nombre es requerido"),
    // correo_electronico: yup.string().email().required("Correo es requerido"),
    comercial_id: yup.number().required("Comercial es requerido"),
    temperatura_id: yup.number().required("Temperatura es requerido"),
    // estado_id: yup.number().required("Temperatura es requerido"),
    como_conocido_id: yup.number().required("Como nos ha conocido es requerido"),
    comunidad_autonoma_id: yup.number().required("Comunidad es requerida"),
    oposicion_id: yup.number().required("Oposicion es requerida"),
    especialidad_id: yup.number().required("Especialidad es requerida"),
    
  });


const AlumnosForm = () => {
    // const classes = useStyles();
    const {id: alumno_id} = useParams()

    let history = useHistory();
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const [loading, setLoading] = useState(false)
    const [alumno, setAlumno] = useState(undefined)
    const [tabSelected, setTabSelected] = React.useState(0);

    const [tutores, setTutores] = useState(undefined)
    const [temperaturas, setTemperaturas] = useState(undefined)
    const [estados, setEstados] = useState(undefined)
    const [comoConocidos, setComoConocidos] = useState(undefined)
    const [comunidadesAutonomas, setComunidadesAutonomas] = useState(undefined)
    const [oposiciones, setOposiciones] = useState(undefined)
    const [especialidades, setEspecialidades] = useState(undefined)
    const [comerciales, setComerciales] = useState(undefined)
    const [situaciones, setSituaciones] = useState(undefined)
    const [showFormProducto, setShowFormProducto] = useState(false)

    const [alumnoProductoId, setAlumnoProductoId] = useState(undefined)

    const [initialValues, setInitialValues] = useState({
        alumno_id:  "",
        nombre: "",
        fecha_alta: null,
        temperatura_id: "",
        estado_id: "",
        como_conocido_id: "",
        comunidad_autonoma_id: "",
        oposicion_id: "",
        especialidad_id: "",
        correo_electronico: "",
        telefono: "",
        es_demo: "",
        ultimo_contacto: null,
        proximo_contacto: null,
        alumno_desde: null,
        fecha_baja: null,
        tutor: "",
        comercial_id: "",
        situacion_id: "",
        observaciones_formulario: "",
        mensaje_formulario: "",
        dni: "",
        direccion: "",
        codigo_postal: "",
        ciudad: "",
        provincia: "",
        pais: "",
        formulario_nombre: "",
        tutor_preferido_id: ""
    })

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        const usuario_id = usuarioSeleccionado.id
        
        setLoading(true);

        alumno_id && getAlumno({usuario_id, alumno_id}).then( (response) => {

            response.alumno && setAlumno(response.alumno)
            
            formik.setValues({
                alumno_id:  response.alumno.alumno_id,
                nombre: response.alumno.nombre,
                fecha_alta: response.alumno.fecha_alta,
                temperatura_id: response.alumno.temperatura_id,
                estado_id: response.alumno.status_id,
                como_conocido_id: response.alumno.como_conocido_id,
                comunidad_autonoma_id: response.alumno.comunidad_autonoma_id,
                oposicion_id: response.alumno.oposicion_id,
                especialidad_id: response.alumno.especialidad_id,
                correo_electronico: response.alumno.correo_electronico,
                telefono: response.alumno.telefono,
                es_demo: response.alumno.es_demo,
                ultimo_contacto: response.alumno.ultimo_contacto,
                proximo_contacto: response.alumno.proximo_contacto,
                fecha_baja: response.alumno.fecha_baja,
                alumno_desde: response.alumno.alumno_desde,
                tutor: response.alumno.tutor,
                comercial_id: response.alumno.comercial_id,
                situacion_id: response.alumno.situacion_id,
                formulario_observaciones: (response.alumno.formulario_observaciones === undefined ? '' : response.alumno.formulario_observaciones),
                mensaje_formulario: (response.alumno.mensaje_formulario === undefined ? '' : response.alumno.mensaje_formulario),
                dni: response.alumno.dni,
                direccion: response.alumno.direccion,
                codigo_postal: response.alumno.codigo_postal,
                ciudad: response.alumno.ciudad,
                provincia: response.alumno.provincia,
                pais: response.alumno.pais,
                formulario_nombre: response.alumno.formulario_nombre,
                tutor_preferido_id: response.alumno.tutor_preferido_id
            })
        })

        getListaDetalle({usuario_id: usuario_id, lista_id: 9}).then( (response) => {
            response.lista_detalle && setTemperaturas(response.lista_detalle)
        })
        
        getListaDetalle({usuario_id: usuario_id, lista_id: 8}).then( (response) => {
            response.lista_detalle && setEstados(response.lista_detalle)
        })

        getListaDetalle({usuario_id: usuario_id, lista_id: 6}).then( (response) => {
            response.lista_detalle && setComoConocidos(response.lista_detalle)
        })

        getListaDetalle({usuario_id: usuario_id, lista_id: 3}).then( (response) => {
            response.lista_detalle && setComunidadesAutonomas(response.lista_detalle)
        })

        getListaDetalle({usuario_id: usuario_id, lista_id: 2}).then( (response) => {
            response.lista_detalle && setOposiciones(response.lista_detalle)
        })

        getListaDetalle({usuario_id: usuario_id, lista_id: 1}).then( (response) => {
            response.lista_detalle && setEspecialidades(response.lista_detalle)
        })
        
        getListaDetalle({usuario_id: usuario_id, lista_id: 7}).then( (response) => {
            response.lista_detalle && setSituaciones(response.lista_detalle)
        })
        
        getComerciales({usuario_id: usuario_id}).then( (response) => {
            response.comerciales && setComerciales(response.comerciales)
        })
        
        getTutores({usuario_id: usuario_id}).then( (response) => {
            response.tutores && setTutores(response.tutores)
        })

    // const [comerciales, setComerciales] = useState(undefined)
    }, [])
    
    useEffect(() => {
        const usuario_id = usuarioSeleccionado.id
        
        alumno_id && getAlumno({usuario_id, alumno_id}).then( (response) => {

            response.alumno && setAlumno(response.alumno)
            
            console.log( response.alumno.alumnos_duplicados.length )

            formik.setValues({
                alumno_id:  response.alumno.alumno_id,
                nombre: response.alumno.nombre,
                fecha_alta: response.alumno.fecha_alta,
                temperatura_id: response.alumno.temperatura_id,
                estado_id: response.alumno.status_id,
                como_conocido_id: response.alumno.como_conocido_id,
                comunidad_autonoma_id: response.alumno.comunidad_autonoma_id,
                oposicion_id: response.alumno.oposicion_id,
                especialidad_id: response.alumno.especialidad_id,
                correo_electronico: response.alumno.correo_electronico,
                telefono: response.alumno.telefono,
                es_demo: response.alumno.es_demo,
                ultimo_contacto: response.alumno.ultimo_contacto,
                proximo_contacto: response.alumno.proximo_contacto,
                alumno_desde: response.alumno.alumno_desde,
                fecha_baja: response.alumno.fecha_baja,
                tutor: response.alumno.tutor,
                comercial_id: response.alumno.comercial_id,
                situacion_id: response.alumno.situacion_id,
                formulario_observaciones: (response.alumno.formulario_observaciones === undefined ? '' : response.alumno.formulario_observaciones),
                mensaje_formulario: (response.alumno.mensaje_formulario === undefined ? '' : response.alumno.mensaje_formulario),
                dni: response.alumno.dni,
                direccion: response.alumno.direccion,
                codigo_postal: response.alumno.codigo_postal,
                ciudad: response.alumno.ciudad,
                provincia: response.alumno.provincia,
                pais: response.alumno.pais,
                formulario_nombre: response.alumno.formulario_nombre,
                tutor_preferido_id: response.alumno.tutor_preferido_id
            })
        })

    // const [comerciales, setComerciales] = useState(undefined)
    }, [showFormProducto])

    /* -------------------------------------------------------------------------- */
    /*                                  UseFormik                                 */
    /* -------------------------------------------------------------------------- */
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true)

            if( alumno === undefined ){

                insertar_alumno( { usuario_id: usuarioSeleccionado.id, campos: values }).then( (response) => {
                    setLoading(false)

                    Swal.fire('Datos insertados correctamente', '', 'info')

                    history.push('/alumnos/detalle/' + response.alumno_id)
                    
                })

            } else {

                values.alumno_id = alumno_id

                update_alumno( { usuario_id: usuarioSeleccionado.id, campos: values }).then( (response) => {

                    Swal.fire('Datos actualizados correctamente', '', 'info')

                    setLoading(false)
                })
    
            }

        },
      });

      
      function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      
      function a11yProps(index) {
        return {
          id: `full-width-tab-${index}`,
          'aria-controls': `full-width-tabpanel-${index}`,
        };
      }

      const handleChangeTab = (event, newValue) => {
        setTabSelected(newValue);
      };
    
      const handleChangeTabIndex = (index) => {
        setTabSelected(index);
      };
      const [anchorElMenuAlumno, setAnchorElMenuAlumno] = React.useState(null);

    // const handleClickMenuAlumno = (event) => {
    //     setAnchorElMenuAlumno(event.currentTarget);
    // };

    // const handleCloseMenuAlumno = () => {
    //     setAnchorElMenuAlumno(null);
    // };
    // const handleComrpobarTelefonoDuplicado = (event) => {
    //     handleCloseMenuAlumno()

    //     console.log("Comprobar si el telefono está duplicado")
    // }
      const columnas_woo_order = [
        { field: 'order_id', headerName: 'Order ID', minWidth: 150, flex: 1 },
        { field: 'order_items', headerName: 'Descripción', minWidth: 150, flex: 1 },
        { field: 'order_total', headerName: 'Importe', minWidth: 150, flex: 1 },
        { field: 'post_date', headerName: 'Fecha', minWidth: 150, flex: 1 },
        { field: 'paid_date', headerName: 'Fecha pago', minWidth: 150, flex: 1 },
        {
            'field': 'post_status', 
            'width':150, 
            'headerName': 'Estado', 
            'description': 'Estado',
            renderCell: (cellValues) => {
                return (
                    <>
                        { cellValues.value === 'wc-cancelled' ? <Chip style={{backgroundColor: red[100]}} className='chipCancelado' size="small" label="Cancelado" /> : '' }
                        { cellValues.value === 'wc-completed' ? <Chip style={{backgroundColor: green[100]}} size="small" label="Completado" />: '' } 
                        { cellValues.value !== 'wc-cancelled' && cellValues.value !== 'wc-completed' ? <Chip style={{backgroundColor: yellow[200]}} size="small" label={cellValues.value} />: '' } 
                    </>
                );
            } 
        },
    ];
      const columnas_alumnos_duplicados = [
        { field: 'id', headerName: 'ID', minWidth: 150, flex: 1, hide: true },
        { field: 'nombre', headerName: 'Nombre', minWidth: 150, flex: 1 },
        {
          'field': 'created', 
          'width':200, 
          'headerName': 'Añadido', 
          'description': 'Añadido',
          type: 'date',
          valueFormatter: params => {
            console.log(params)
            return moment(params?.value).format("DD/MM/YYYY hh:mm A")
          },
          renderCell: (cellValues) => {
            return (
              
                (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
        
                <Moment format="DD/MM/YYYY HH:mm:ss">
                  {cellValues.value}
                </Moment>
        
                :
        
                <></>
        
              
            );
          } 
      },
        { field: 'temperatura_nombre', headerName: 'Temperatura',minWidth: 120 },
        { field: 'comercial_nombre', headerName: 'Comercial',minWidth: 100, flex: 1 },
        { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad',minWidth: 100, flex: 1 },
        { field: 'oposicion_nombre', headerName: 'Oposición',minWidth: 100, flex: 1 },
        { field: 'especialidad_nombre', headerName: 'Especialidad',minWidth: 10, flex: 1 },
        { field: 'como_conocido_nombre_lista', headerName: 'Como conocido',minWidth: 100, flex: 1 },
    ];
    const columnas_notas = [
        { field: 'nota', headerName: 'Nota', flex: 1 },
        { field: 'created', headerName: 'Creado', minWidth: 150, flex: 1 },
    ];
    const columnas_productos = [
        { field: 'producto_nombre', headerName: 'Producto', flex: 1 },
        { field: 'num_cuotas', headerName: 'Num. coutas', flex: 1 },
        { field: 'forma_pago_nombre', headerName: 'Forma de pago', flex: 1 },
        { field: 'importe', headerName: 'Importe', flex: 1 },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
            
              <GridActionsCellItem
                // icon={<SendIcon />}
                label="Abrir"
                onClick={() => handleProductoDetalle(params.row.id)}
                showInMenu
              />,
            ],
        },
    ];

    const handleProductoAdd = () => {
        setAlumnoProductoId(undefined)
        setShowFormProducto(true)
    }

    const handleProductoDetalle = (alumno_producto_id) => {

        setAlumnoProductoId(alumno_producto_id)
        setShowFormProducto((true))

    }
    const handleAlumnoDetalle = (id) => {
        history.push(id )
        history.go(0)
      }
    return (
        <>

            <TemplateDashboard showBack={true}>
                
                <TemplateForm
                    tituloForm={ alumno === undefined ? "Añadir alumno" : "Actualizar alumno" }
                    onSubmit={formik.handleSubmit}
                >
                    { alumno && <AlumnoStatus style={{paddingTop: 30, paddingBottom: 30}} alumno_id={alumno.id} status_id={alumno.status_id} fecha_baja={alumno.fecha_baja}/> }
                    { !alumno && <Box my={6}> Cargando estados o nuevo alumno...</Box>}

                    <Grid container  spacing={2}>

                        <Grid item xs={12} md={12}>

                            <Typography variant='h6'>Datos de facturación</Typography>

                        </Grid>

                        <Grid item xs={12} md={2}>

                            <TextField
                                id="dni"
                                name="dni"
                                label="DNI/CIF"
                                placeholder="Introduce el DNI"
                                fullWidth
                                margin="normal"
                                value={formik.values.dni}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.dni && Boolean(formik.errors.dni)}
                                helperText={formik.touched.dni && formik.errors.dni}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid item xs={12} md={6}>

                            <TextField
                                id="direccion"
                                name="direccion"
                                label="Direccion"
                                placeholder="Introduce la direccion"
                                fullWidth
                                margin="normal"
                                value={formik.values.direccion}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.direccion && Boolean(formik.errors.direccion)}
                                helperText={formik.touched.direccion && formik.errors.direccion}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid item xs={12} md={2}>

                            <TextField
                                id="codigo_postal"
                                name="codigo_postal"
                                label="C.P"
                                placeholder="Introduce Código Postal"
                                fullWidth
                                margin="normal"
                                value={formik.values.codigo_postal}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.codigo_postal && Boolean(formik.errors.codigo_postal)}
                                helperText={formik.touched.codigo_postal && formik.errors.codigo_postal}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid item xs={12} md={2}>

                            <TextField
                                id="ciudad"
                                name="ciudad"
                                label="ciudad"
                                placeholder="Introduce la ciudad"
                                fullWidth
                                margin="normal"
                                value={formik.values.ciudad}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.ciudad && Boolean(formik.errors.ciudad)}
                                helperText={formik.touched.ciudad && formik.errors.ciudad}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid item xs={12} md={2}>

                            <TextField
                                id="provincia"
                                name="provincia"
                                label="Provincia"
                                placeholder="Introduce la provincia"
                                fullWidth
                                margin="normal"
                                value={formik.values.provincia}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.provincia && Boolean(formik.errors.provincia)}
                                helperText={formik.touched.provincia && formik.errors.provincia}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid item xs={12} md={2}>

                            <TextField
                                id="pais"
                                name="pais"
                                label="Pais"
                                placeholder="Introduce el pais"
                                fullWidth
                                margin="normal"
                                value={formik.values.pais}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.pais && Boolean(formik.errors.pais)}
                                helperText={formik.touched.pais && formik.errors.pais}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid item xs={12} md={12}>

                            <Typography display="inline" variant='h6'>Datos del alumno</Typography>

                            {
                                alumno?.es_telefono_duplicado === '1'
                                ?
                                <Chip style={{marginLeft: 10}} size='small' label='Telefono duplicado' />
                                :
                                <></>
                            }
                            {
                                alumno?.alumnos_duplicados.length >= 1
                                ?
                                <Chip style={{marginLeft: 10}} size='small' label='Tiene fichas duplicadas' />
                                :
                                <></>
                            }
                            {/* <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleClickMenuAlumno}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorElMenuAlumno}
                                keepMounted
                                open={Boolean(anchorElMenuAlumno)}
                                onClose={handleCloseMenuAlumno}
                            >
                                <MenuItem onClick={ (e) => handleComrpobarTelefonoDuplicado(e) }>Comprobar télfono duplicado</MenuItem>
                            </Menu> */}

                        </Grid>

                        <Grid item xs={12} md={3}>

                            <TextField
                                id="nombre"
                                name="nombre"
                                label="Nombre"
                                placeholder="Introduce el nombre"
                                fullWidth
                                margin="normal"
                                value={formik.values.nombre}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                helperText={formik.touched.nombre && formik.errors.nombre}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid item xs={12} md={4}>

                            <TextField
                                id="correo_electronico"
                                name="correo_electronico"
                                label="Correo electrónico"
                                placeholder="Introduce el correo electrónico"
                                fullWidth
                                margin="normal"
                                value={formik.values.correo_electronico}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.correo_electronico && Boolean(formik.errors.correo_electronico)}
                                helperText={formik.touched.correo_electronico && formik.errors.correo_electronico}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>

                        <Grid item xs={12} md={3}>

                            <Stack direction='row'>
                                <TextField
                                    id="telefono"
                                    name="telefono"
                                    label="Teléfono"
                                    placeholder="Introduce el teléfono"
                                    fullWidth
                                    margin="normal"
                                    value={formik.values.telefono}
                                    onChange={formik.handleChange}
                                    variant="outlined"
                                    error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                                    helperText={formik.touched.telefono && formik.errors.telefono}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <a style={{paddingTop: 30, marginLeft: 10, color: '#25D366'}} href={`https://api.whatsapp.com/send?phone=34${formik.values.telefono}`} target='_blank'>
                                    <WhatsAppIcon />
                                </a>
                                <a style={{paddingTop: 30, marginLeft: 10, color: '#072236'}} href={`tel:+34${formik.values.telefono}`} target='_blank'>
                                    <PhoneIcon />
                                </a>
                            </Stack>
                        </Grid>

                        <Grid item xs={6} md={2} style={{marginTop: 16}}>

                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                
                                    fullWidth
                                    id="fecha_alta"
                                    label="Fecha Alta"
                                    placeholder='Introduce la fecha de alta'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={formik.values.fecha_alta}
                                    onChange={value => formik.setFieldValue("fecha_alta", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.fecha_alta && Boolean(formik.errors.fecha_alta)}
                                    helperText={formik.touched.fecha_alta && formik.errors.fecha_alta}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>
                        
                        {/* <Grid item xs={6} md={2} style={{marginTop: 16}}>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    fullWidth
                                    id="ultimo_contacto"
                                    label="Ultimo contacto"
                                    placeholder='Ultimo contacto'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={formik.values.ultimo_contacto}
                                    onChange={value => formik.setFieldValue("ultimo_contacto", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.ultimo_contacto && Boolean(formik.errors.ultimo_contacto)}
                                    helperText={formik.touched.ultimo_contacto && formik.errors.ultimo_contacto}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid> */}

                        <Grid item xs={6} md={2} style={{marginTop: 16}}>

                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDateTimePicker
                                    fullWidth
                                    autoOk={true}
                                    id="proximo_contacto"
                                    label="Proximo contacto"
                                    placeholder='Proximo contacto'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy HH:mm:ss"
                                    value={formik.values.proximo_contacto}
                                    onChange={value => formik.setFieldValue("proximo_contacto", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.proximo_contacto && Boolean(formik.errors.proximo_contacto)}
                                    helperText={formik.touched.proximo_contacto && formik.errors.proximo_contacto}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>
                        
                        <Grid item xs={6} md={2} style={{marginTop: 16}}>

                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                    fullWidth
                                    id="alumno_desde"
                                    label="Alumno desde"
                                    placeholder='Alumno desde'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={formik.values.alumno_desde}
                                    onChange={value => formik.setFieldValue("alumno_desde", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.alumno_desde && Boolean(formik.errors.alumno_desde)}
                                    helperText={formik.touched.alumno_desde && formik.errors.alumno_desde}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>

                        <Grid item xs={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.temperatura_id && Boolean(formik.errors.temperatura_id)}
                            >
                                <InputLabel id="temperatura_id-label">Temperatura</InputLabel>
                                <Select
                                    labelId="temperatura_id-label"
                                    id="temperatura_id"
                                    name="temperatura_id"
                                    label="Temperatura"
                                    fullWidth
                                    value={formik.values.temperatura_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.temperatura_id && Boolean(formik.errors.temperatura_id)}
                                >
                                    
                                    {        
                                        temperaturas && temperaturas.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.temperatura_id && formik.errors.temperatura_id}</FormHelperText>
                            </FormControl>
                        </Grid>
                        
                        {/* <Grid item xs={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.estado_id && Boolean(formik.errors.estado_id)}
                            >
                                <InputLabel id="estado_id-label">Estado</InputLabel>
                                <Select
                                    labelId="estado_id-label"
                                    id="estado_id"
                                    name="estado_id"
                                    label="Estado"
                                    fullWidth
                                    value={formik.values.estado_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.estado_id && Boolean(formik.errors.estado_id)}
                                >
                                    {        
                                        estados && estados.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.estado_id && formik.errors.estado_id}</FormHelperText>
                            </FormControl>
                        </Grid> */}
                        
                        <Grid item xs={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.como_conocido_id && Boolean(formik.errors.como_conocido_id)}
                            >
                                <InputLabel id="como_conocido_id-label">Como nos ha conocido</InputLabel>
                                <Select
                                    labelId="como_conocido_id-label"
                                    id="como_conocido_id"
                                    name="como_conocido_id"
                                    label="Como nos ha conocido"
                                    fullWidth
                                    value={formik.values.como_conocido_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.como_conocido_id && Boolean(formik.errors.como_conocido_id)}
                                >
                                    {        
                                        comoConocidos && comoConocidos.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.como_conocido_id && formik.errors.como_conocido_id}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2}>

                            <TextField
                                id="formulario_nombre"
                                name="formulario_nombre"
                                label="Formulario"
                                placeholder=""
                                fullWidth
                                readonly
                                margin="normal"
                                value={formik.values.formulario_nombre}
                                variant="outlined"
                                error={formik.touched.formulario_nombre && Boolean(formik.errors.formulario_nombre)}
                                helperText={formik.touched.formulario_nombre && formik.errors.formulario_nombre}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.comunidad_autonoma_id && Boolean(formik.errors.comunidad_autonoma_id)}
                            >
                                <InputLabel id="comunidad_autonoma-label">Comunidad autónoma</InputLabel>
                                <Select
                                    labelId="comunidad_autonoma_id-label"
                                    id="comunidad_autonoma_id"
                                    name="comunidad_autonoma_id"
                                    label="Comunidad autonoma"
                                    fullWidth
                                    value={formik.values.comunidad_autonoma_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.comunidad_autonoma_id && Boolean(formik.errors.comunidad_autonoma_id)}
                                >
                                    {        
                                        comunidadesAutonomas && comunidadesAutonomas.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.comunidad_autonoma_id && formik.errors.comunidad_autonoma_id}</FormHelperText>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.oposicion_id && Boolean(formik.errors.oposicion_id)}
                            >
                                <InputLabel id="oposicion_id-label">Oposicion</InputLabel>
                                <Select
                                    labelId="oposicion_id-label"
                                    id="oposicion_id"
                                    name="oposicion_id"
                                    label="Oposicion"
                                    fullWidth
                                    value={formik.values.oposicion_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.oposicion_id && Boolean(formik.errors.oposicion_id)}
                                >
                                    {        
                                        oposiciones && oposiciones.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.oposicion_id && formik.errors.oposicion_id}</FormHelperText>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.especialidad_id && Boolean(formik.errors.especialidad_id)}
                            >
                                <InputLabel id="especialidad_id-label">Especialidad</InputLabel>
                                <Select
                                    labelId="especialidad_id-label"
                                    id="especialidad_id"
                                    name="especialidad_id"
                                    label="Especialidad"
                                    fullWidth
                                    value={formik.values.especialidad_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.especialidad_id && Boolean(formik.errors.especialidad_id)}
                                >
                                    {        
                                        especialidades && especialidades.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.especialidad_id && formik.errors.especialidad_id}</FormHelperText>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.situacion_id && Boolean(formik.errors.situacion_id)}
                            >
                                <InputLabel id="situacion_id-label">Situación del alumno</InputLabel>
                                <Select
                                    labelId="situacion_id-label"
                                    id="situacion_id"
                                    name="situacion_id"
                                    label="Situación del alumno"
                                    fullWidth
                                    value={formik.values.situacion_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.situacion_id && Boolean(formik.errors.situacion_id)}
                                >
                                    {        
                                        situaciones && situaciones.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.situacion_id && formik.errors.situacion_id}</FormHelperText>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.comercial_id && Boolean(formik.errors.comercial_id)}
                            >
                                <InputLabel id="comercial_id-label">Comercial</InputLabel>
                                <Select
                                    labelId="comercial_id-label"
                                    id="comercial_id"
                                    name="comercial_id"
                                    label="Comercial"
                                    fullWidth
                                    // readOnly = { usuarioSeleccionado.perfil_id !== "1" ? true : false}
                                    value={formik.values.comercial_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.comercial_id && Boolean(formik.errors.comercial_id)}
                                >
                                    {        
                                        comerciales && comerciales.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.comercial_id && formik.errors.comercial_id}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formik.touched.tutor_preferido_id && Boolean(formik.errors.tutor_preferido_id)}
                            >
                                <InputLabel id="tutor_preferido_id-label">Tutor preferido</InputLabel>
                                <Select
                                    labelId="tutor_preferido_id-label"
                                    id="tutor_preferido_id"
                                    name="tutor_preferido_id"
                                    label="Tutor Preferido"
                                    fullWidth
                                    value={formik.values.tutor_preferido_id}
                                    onChange={formik.handleChange}
                                    error={formik.touched.tutor_preferido_id && Boolean(formik.errors.tutor_preferido_id)}
                                >
                                    
                                    <MenuItem key={0} value={0}>Sin definir</MenuItem>
                                    {        

                                        tutores && tutores.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formik.touched.tutor_preferido_id && formik.errors.tutor_preferido_id}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} md={2} style={{marginTop: 16}}>

                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                <KeyboardDatePicker
                                    fullWidth
                                    id="fecha_baja"
                                    label="Fecha baja"
                                    placeholder='Fecha baja'
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    value={formik.values.fecha_baja}
                                    onChange={value => formik.setFieldValue("fecha_baja", value)}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date"
                                    }}
                                    error={formik.touched.fecha_baja && Boolean(formik.errors.fecha_baja)}
                                    helperText={formik.touched.fecha_baja && formik.errors.fecha_baja}
                                />
                            </MuiPickersUtilsProvider>

                        </Grid>

                        <Grid item xs={12}>

                            <TextField
                                id="formulario_observaciones"
                                name="formulario_observaciones"
                                label="Observaciones"
                                placeholder="Introduce las observaciones"
                                fullWidth
                                multiline
                                rows={4}
                                margin="normal"
                                value={formik.values.formulario_observaciones}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.formulario_observaciones && Boolean(formik.errors.formulario_observaciones)}
                                helperText={formik.touched.formulario_observaciones && formik.errors.formulario_observaciones}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true,
                                  }}
                            />
                            <TextField
                                id="mensaje_formulario"
                                name="mensaje_formulario"
                                label=""
                                fullWidth
                                multiline
                                rows={4}
                                margin="normal"
                                value={formik.values.mensaje_formulario}
                                onChange={formik.handleChange}
                                variant="outlined"
                                error={formik.touched.mensaje_formulario && Boolean(formik.errors.mensaje_formulario)}
                                helperText={formik.touched.mensaje_formulario && formik.errors.mensaje_formulario}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: true,
                                  }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Divider />
                        </Grid>

                    </Grid>

                    

                    
                </TemplateForm>

                
                { 
                    alumno_id && <Box sx={{marginX: 2, marginY: 12}}>
                        <Paper>
                            <AppBar position="static" color="default">
                                <Tabs
                                value={tabSelected}
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                // variant="fullWidth"
                                aria-label="full width tabs example"
                                >
                                <Tab label="Acciones comerciales" {...a11yProps(0)} />
                                <Tab label="Pedidos web" {...a11yProps(1)} />
                                <Tab label="Productos" {...a11yProps(2)} />
                                <Tab label="Fichas duplicadas" {...a11yProps(3)} />
                                {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                                </Tabs>
                            </AppBar>
                            <SwipeableViews
                                axis={'x'}
                                index={tabSelected}
                                onChangeIndex={handleChangeTabIndex}
                            >
                                {/* Acciones comerciales */}
                                <TabPanel value={tabSelected} index={0}>
                                    { alumno && <AlumnosNotasForm
                                        alumno_id={alumno_id}
                                        notas={alumno.notas}
                                    />}

                                </TabPanel>

                                {/* Pedidos web */}
                                <TabPanel value={tabSelected} index={1}>
                                    { alumno && 
                                        <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                                            <DataGrid 
                                                rows={alumno.woo_order} 
                                                columns={columnas_woo_order} 
                                                // rowHeight={30}
                                                // components={{ Toolbar: QuickSearchToolbar }}
                                                // onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                                                // componentsProps={{
                                                    // toolbar: {
                                                    // value: searchText,
                                                //     onChange: (event) => requestSearch(event.target.value),
                                                //     clearSearch: () => requestSearch(''),
                                                    // },
                                                // }}
                                            />
                                        </div>
                                    }
                                </TabPanel>

                                {/* Productos */}
                                <TabPanel value={tabSelected} index={2}>

                                    {
                                        showFormProducto ?

                                        <AlumnosProductoForm
                                            alumno_id={alumno_id}
                                            alumno_producto_id={alumnoProductoId}
                                            setShowFormProducto={setShowFormProducto}
                                            setAlumno={setAlumno}
                                        />

                                        :

                                        <>
                                            <Button 
                                                variant="contained" 
                                                color="primary" 
                                                type="submit"
                                                style={{marginTop: '16px', marginBottom: '16px'}}
                                                onClick={ () => handleProductoAdd() }
                                            >
                                                Añadir producto
                                            </Button>
                                        
                                            { alumno && 
                                                <div style={{ height: 500, width: '100%' }}>
                                                    <DataGrid 
                                                        rows={alumno.productos} 
                                                        columns={columnas_productos} 
                                                        onRowDoubleClick={ (evento ) => handleProductoDetalle(evento.id) }
                                                    />
                                                </div>
                                            }
                                        </>
                                    }

                                </TabPanel>

                                {/* Fichas duplicadas */}
                                <TabPanel value={tabSelected} index={3}>
                                { alumno && 
                                                <div style={{ height: 500, width: '100%' }}>
                                                    <DataGrid 
                                                        rows={alumno.alumnos_duplicados} 
                                                        columns={columnas_alumnos_duplicados} 
                                                        onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                                                    />
                                                </div>
                                            }
                                </TabPanel>
                            </SwipeableViews>
                        </Paper>
                    </Box>
                }
                
                

            </TemplateDashboard>

        </>
    )
};

export default AlumnosForm;