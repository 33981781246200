import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../components/TemplateDashboard";
import VisionCuotasContent from './components/VisionCuotasContent';

const VisionCuotas = () => {

    return (
        <>

            <TemplateDashboard>
                <VisionCuotasContent />
            </TemplateDashboard>

        </>
    )
};

export default VisionCuotas;