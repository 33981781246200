import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AnaliticaPivotContent from './components/AnaliticaPivotContent';

const AnaliticaPivot = () => {

    return (
        <>

            <TemplateDashboard>
                <AnaliticaPivotContent />
            </TemplateDashboard>

        </>
    )
};

export default AnaliticaPivot;