import React, { useEffect } from 'react'
import { useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import LoadingComponent from '../../../../components/Loading';

import { useSelector } from "react-redux";

import {
    selectUsuarioSeleccionado,
} from "../../../../redux/reducer/loginReducer";
import EmptyComponent from '../../../../components/EmptyContent';
// import { deleteInquilino, getAlumnos, inquilinoDelete } from '../../../../services/Alumnos';
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { DataGrid, GridActionsCellItem, GridToolbarDensitySelector, GridToolbarFilterButton} from '@mui/x-data-grid';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import TituloPagina from '../../../../components/Titulos/TituloPagina';
import AddIcon from '@material-ui/icons/Add';
import { getAlumnos, getAlumnosSinAsignar, update_comercial } from '../../../../services/alumnos';
import { Stack } from '@mui/system';
import {sincronizar_formularios, sincronizar_notion, sincronizar_woo_order} from '../../../../services/sincronizar'
import { getComerciales } from '../../../../services/comerciales';
import Moment from 'react-moment';
import DataGridInnovattio from '../../../../components/DataGridInnovatiio';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

// import Moment from 'react-moment';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '80px'
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh"
    },
    emptyContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        width: '100%',
        flexDirection: 'column',
        textAlign: 'center'
    },
    emptyImage: {
        width: '100%'
    },
    rootFilter: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    textFieldFilter: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    enlace: {
      color: theme.palette.link.main,
      textDecoration: 'none'
    }
}));

export default function AlumnosAsignacionMasivaContent() {
    const classes = useStyles();

    const [Alumnos, setAlumnos] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingMensaje, setLoadingMensaje] = useState("")

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const history = useHistory()

    const [selectionModel, setSelectionModel] = React.useState([]);
    const [comercialSeleccionado, setComercialSeleccionado] = React.useState([]);

    const [comerciales, setComerciales] = useState(undefined)

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        setLoading(true)
        setLoadingMensaje("Cargando alumnos")

        const usuario_id = usuarioSeleccionado.id

        getAlumnosSinAsignar({ usuario_id }).then((response) => {

            response && setAlumnos(response.alumnos)
            console.log(response);
            setLoading(false)
        });

        getComerciales({usuario_id: usuario_id}).then( (response) => {
            response.comerciales && setComerciales(response.comerciales)
        })
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                           Definicion de columnas                           */
    /* -------------------------------------------------------------------------- */

    const columnas = 
    [
        { field: 'id', hide: true, headerName: 'ID' },
        { field: 'nombre', headerName: 'Nombre', minWidth: 150, flex: 1 },
        { field: 'correo_electronico', headerName: 'Correo electrónico', minWidth: 250, flex: 1 },
        {
            'field': 'telefono', 
            'width':150, 
            'headerName': 'Teléfono', 
            'description': 'Teléfono',
            renderCell: (cellValues) => {
                return (
                    <a className={classes.enlace} href={`tel:${cellValues.value}`}>
                        {cellValues.value}
                    </a>
                );
            } 
        },
        { field: 'temperatura_nombre', headerName: 'Temperatura',minWidth: 120 },
        { field: 'status_nombre', headerName: 'Estado',minWidth: 120 },
        { field: 'comercial_nombre', headerName: 'Comercial',minWidth: 100, flex: 1 },
        { field: 'comunidad_autonoma_nombre', headerName: 'Comunidad',minWidth: 100, flex: 1 },
        { field: 'oposicion_nombre', headerName: 'Oposición',minWidth: 100, flex: 1 },
        { field: 'especialidad_nombre', headerName: 'Especialidad',minWidth: 10, flex: 1 },
        { field: 'como_conocido_nombre', headerName: 'Como conocido',minWidth: 10, flex: 1 },
        { field: 'formulario_nombre', headerName: 'Formulario',minWidth: 10, flex: 1 },
        {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: (params) => [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Modificar"
              component={Link}
              to={`alumnos/detalle/${params.id}`}
              showInMenu
            />,
            // <GridActionsCellItem
            //   icon={<DeleteIcon />}
            //   label="Eliminar"
            //   onClick={() => linkDelete(params.id)}
            //   showInMenu
            // />,
          ],
        }
    ]
    ;

    /* -------------------------------------------------------------------------- */
    /*                                  Contenido                                 */
    /* -------------------------------------------------------------------------- */
    function QuickSearchToolbar(props) {
        const classes = useStyles();
      
        return (
          <div className={classes.rootFilter}>
            <div>
              <GridToolbarFilterButton />
              <GridToolbarDensitySelector />
            </div>
            <TextField
              variant="standard"
              value={props.value}
              onChange={props.onChange}
              placeholder="Buscar…"
              className={classes.textFieldFilter}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                  <IconButton
                    title="Clear"
                    aria-label="Clear"
                    size="small"
                    style={{ visibility: props.value ? 'visible' : 'hidden' }}
                    onClick={props.clearSearch}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                ),
              }}
            />
          </div>
        );
      }
      
      QuickSearchToolbar.propTypes = {
        clearSearch: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
      };

    const Contenido = () => {
        const [rows, setRows] = React.useState(Alumnos);
        const [searchText, setSearchText] = React.useState('');
        const requestSearch = (searchValue) => {
          // console.log(searchValue)
          setSearchText(searchValue);
          const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
          // const searchRegex = new RegExp(`.*${searchValue}.*`, 'ig');
          const filteredRows = Alumnos.filter((row) => {
              return Object.keys(row).some((field) => {
                  // console.log(field)
                  // console.log(row)
                  return searchRegex.test(row[field]);
              });
          });
          setRows(filteredRows);
        };

        const handleAlumnoDetalle = (id) => {
          history.push('alumnos/detalle/' + id )
        }

        const handleAsignarComercial = () => {
          let usuario_id = usuarioSeleccionado.id
          selectionModel.map( (alumno_id) => {
            update_comercial({
              usuario_id: usuario_id,
              alumno_id: alumno_id,
              comercial_id: comercialSeleccionado
            }).then( (response) => {
              setLoading(true)
              getAlumnos({ usuario_id: usuario_id }).then((response) => {

                response && setAlumnos(response.alumnos)
                setLoading(false)
                
            });
            })
          })
        }

        return (
            rows ?
                <>
                    <TituloPagina titulo={"Alumnos"} />


                        <Grid xs={6}>
                          <FormControl
                              variant="outlined"
                              fullWidth
                          >
                            <Stack direction='row' mb={2} mx={2} spacing={1}>

                              <Grid xs={6}>

                                <InputLabel style={{paddingLeft: 20}} id="comercial_id-label">Comercial</InputLabel>
                                <Select
                                    labelId="comercial_id-label"
                                    id="comercial_id"
                                    name="comercial_id"
                                    label="Comercial"
                                    fullWidth
                                    value={comercialSeleccionado}
                                    onChange={ (value) => { setComercialSeleccionado(value.target.value)} }
                                    // error={formik.touched.comercial_id && Boolean(formik.errors.comercial_id)}
                                >
                                  {        
                                      comerciales && comerciales.map( (item) => {
                                          return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                      })
                                  }

                                </Select>
                              </Grid>

                              <Grid xs={6}>

                                <Button
                                  variant="contained"
                                  color="primary"
                                  startIcon={<AddIcon />}
                                  fullWidth
                                  onClick={ () => handleAsignarComercial() }
                                >
                                Asignar comercial
                                </Button>
                                
                              </Grid>
                              
                            </Stack>
                          
                          </FormControl>

                        </Grid>
                        

                    <div style={{ height: 500, width: '100%', padding:"0 15px" }}>
                        <DataGrid 
                            rows={rows} 
                            columns={columnas} 
                            rowHeight={30}
                            components={{ Toolbar: QuickSearchToolbar }}
                            onRowDoubleClick={ (evento ) => handleAlumnoDetalle(evento.id) }
                            checkboxSelection={usuarioSeleccionado.perfil_id === '1'}
                            onSelectionModelChange={(newSelectionModel) => {
                              setSelectionModel(newSelectionModel);
                            }}
                            selectionModel={selectionModel}
                            componentsProps={{
                                toolbar: {
                                  value: searchText,
                                  onChange: (event) => requestSearch(event.target.value),
                                  clearSearch: () => requestSearch(''),
                                },
                            }}
                        />
                    </div>

                    {/* <Typography variant='caption' style={{marginTop: 10, marginLeft: 16}}>NOTA: Ordenado por fecha de entrada de gasto decreciente</Typography> */}

                </>
                
                :
                <EmptyComponent mensaje="No hay datos para mostrar" />
        )
    }
    
    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <div className={classes.container}>
            {
                (loading ?
                    
                    <LoadingComponent isFullScreen={true} mensaje={loadingMensaje} />
                    
                    :
                    
                    <Contenido />
                )
            }
        </div>
    )
}
