import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";
import AlumnosAsignacionMasivaContent from './components/AlumnosAsignacionMasivaContent';

const AlumnosAsignacionMasivaListado = () => {

    return (
        <>

            <TemplateDashboard>
                <AlumnosAsignacionMasivaContent />
            </TemplateDashboard>

        </>
    )
};

export default AlumnosAsignacionMasivaListado;