import { AppBar, Box, Button, Chip, Divider, FormControl, FormHelperText, Grid, Hidden, Input, InputLabel, makeStyles, MenuItem, Paper, Select, Tab, Tabs, TextField, Tooltip, Typography } from '@material-ui/core';
import { useFormik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// import "../../assets/css/master.css";

import { selectUsuarioSeleccionado } from '../../../redux/reducer/loginReducer';
import * as yup from 'yup';
// import { addFactura } from '../../../services/inquilino';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import TemplateForm from '../../../components/TemplateForm/TemplateForm';
import { insert_nota } from '../../../services/alumnos_notas';
import { getProductos } from '../../../services/productos';
import { getAlumnosProductos, insert_producto } from '../../../services/alumnos_productos';
import TituloForm from '../../../components/Titulos/TituloForm';
import { getAlumno } from '../../../services/alumnos';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { crear_abono_alumno_producto_cuota, crear_enlace_pago, crear_factura_alumno_producto_cuota, eliminar_alumno_producto_cuota, enviar_cobro_alumno_producto_cuota, enviar_cobro_manual_alumno_producto_cuota, enviar_cobro_producto_cuota, enviar_enlace_pago_alumno_producto_cuota, insertar_alumno_producto_cuota } from '../../../services/alumnos_productos_cuotas';
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SendIcon from '@material-ui/icons/Send';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import { getListaDetalle } from '../../../services/lista_detalle';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 90
    },
    formulario: {
        margin: 8
    },
    input: {
        display: 'none'
    },
}));

/* -------------------------------------------------------------------------- */
/*                            Esquema de valicación                           */
/* -------------------------------------------------------------------------- */
const validationSchemaProducto = yup.object({
    producto_id: yup.number().required("Producto es requerido"),
    num_cuotas: yup.number().required("Numero de cuotas es requerido"),
    importe_cuota: yup.number().required("Importe es requerido"),
    fecha_inicio: yup.string().required("Fecha es requerida"),
    forma_pago_id: yup.string().required("Forma de pago es requerida"),
    
});
const validationSchemaCuota = yup.object({
    fecha: yup.string().required("Fecha es requerida"),
    importe: yup.number().required("Importe es requerido"),
});


const AlumnosProductoForm = ({alumno_id, alumno_producto_id, setShowFormProducto, setAlumno}) => {
    // const classes = useStyles();

    let history = useHistory();
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });
    const [loading, setLoading] = useState(false)
    const [loadingCuotas, setLoadingCuotas] = useState(false)
    // const [alumno, setAlumno] = useState(undefined)
    const [productos, setProductos] = useState([])
    const [alumnoProducto, setAlumnoProducto] = useState(undefined)
    const [formasPago, setFormasPago] = useState(undefined)

    const [initialValuesProducto, setInitialValuesProducto] = useState({
        alumno_id: alumno_id,
        producto_id:  "",
        num_cuotas:  1,
        importe_cuota:  "",
        fecha_inicio: null,
        forma_pago_id: ""
    })
    const [initialValuesCuota, setInitialValuesCuota] = useState({
        alumno_producto_id: alumno_producto_id,
        fecha:  null,
        importe:  ""
    })

    /* -------------------------------------------------------------------------- */
    /*                                  UseEffect                                 */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        
        const usuario_id = usuarioSeleccionado.id
        // setLoading(true);
        setLoadingCuotas(true)

        !alumno_producto_id && getProductos({usuario_id}).then( (response) => {

            response && setProductos(response.productos)

            // setLoading(false);
        })

        alumno_producto_id && getAlumnosProductos({ 
            usuario_id: usuario_id,
            alumno_producto_id: alumno_producto_id
        }).then( (response) => {
            setLoadingCuotas(false)
            setAlumnoProducto(response.alumno_producto)
        })

        getListaDetalle({usuario_id: usuario_id, lista_id: 12}).then( (response) => {
            response.lista_detalle && setFormasPago(response.lista_detalle)
        })
        
    // const [comerciales, setComerciales] = useState(undefined)
    }, [])

    /* -------------------------------------------------------------------------- */
    /*                                  UseFormik                                 */
    /* -------------------------------------------------------------------------- */
    const formikProducto = useFormik({
        initialValues: initialValuesProducto,
        validationSchema: validationSchemaProducto,
        onSubmit: (values) => {

            Swal.fire({
                title: 'Se va a proceder a crear un producto para este alumno con el plan de pagos seleccionado ¿quieres continuar?',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: `Cancelar`,
                confirmButtonColor: '#afcb0d',
                cancelButtonColor: '#a5a5a5',
                icon: 'warning',
                reverseButtons: true
            }).then((result) => {

                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    
                    insert_producto({
                        usuario_id: usuarioSeleccionado.id,
                        alumno_id: alumno_id,
                        producto_id: values.producto_id,
                        num_cuotas: values.num_cuotas,
                        importe_cuota: values.importe_cuota,
                        fecha_inicio: values.fecha_inicio,
                        forma_pago_id: values.forma_pago_id
                    }).then( (response) => {
                    
                        getAlumno({
                            alumno_id: alumno_id,
                            usuario_id: usuarioSeleccionado.id
                        }).then( (response2) => {
        
                            setAlumno(response2.alumno)
                            setShowFormProducto(false)
        
                        })
                        
                    })

                } else if (result.isDenied) {
                //   Swal.fire('Changes are not saved', '', 'info')
                }
            })

            

        },
    });
    const formikCuota = useFormik({
        initialValues: initialValuesCuota,
        validationSchema: validationSchemaCuota,
        onSubmit: (values) => {

            insertar_alumno_producto_cuota({
                usuario_id: usuarioSeleccionado.id,
                alumno_producto_id: alumno_producto_id,
                producto_id: values.producto_id,
                fecha: values.fecha,
                importe: values.importe
            }).then( (response) => {
                // setShowFormProducto(false)
                alumno_producto_id && getAlumnosProductos({ 
                    usuario_id: usuarioSeleccionado.id,
                    alumno_producto_id: alumno_producto_id
                }).then( (response) => {
                    setAlumnoProducto(response.alumno_producto)
                })
            })

        },
    });

    /* -------------------------------------------------------------------------- */
    /*                                  columnas                                  */
    /* -------------------------------------------------------------------------- */
    const columnas_cuotas = [
        { field: 'id', headerName: 'ID', width: 80 },
        { field: 'fecha', headerName: 'Fecha', flex: 1 },
        { field: 'importe', headerName: 'Importe', flex: 1 },
        {
            'field': 'estado_nombre', 
            'width':150, 
            'headerName': 'Estado', 
            'description': 'Estado',
            renderCell: (cellValues) => {
              console.log(cellValues.row.codigo_cobro_descripcion)
                return (
                  cellValues.row.codigo_cobro_descripcion !== "" && cellValues.row.codigo_cobro_descripcion !== null ? 
  
                  <Tooltip title={cellValues.row.codigo_cobro_descripcion} >
                    <span className="table-cell-trucate">{cellValues.row.estado_nombre}</span>
                  </Tooltip>
                  
                  :
                  
                  <span className="table-cell-trucate">{cellValues.row.estado_nombre}</span>
  
                );
            } 
        },
        {
            'field': 'fecha_envio', 
            'width':200, 
            'headerName': 'F. Envio', 
            'description': 'Fecha Envio',
            type: 'date',
            renderCell: (cellValues) => {
              return (
                
                  (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
          
                  <Moment format="DD/MM/YYYY">
                    {cellValues.value}
                  </Moment>
          
                  :
          
                  <></>
          
                
              );
            } 
        },
        {
            'field': 'fecha_cobro', 
            'width':200, 
            'headerName': 'F. Cobro', 
            'description': 'Fecha Cobro',
            type: 'date',
            renderCell: (cellValues) => {
              return (
                
                  (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
          
                  <Moment format="DD/MM/YYYY">
                    {cellValues.value}
                  </Moment>
          
                  :
          
                  <></>
          
                
              );
            } 
        },
        {
            'field': 'ultima_fecha_factura', 
            'width':200, 
            'headerName': 'F. Factura', 
            'description': 'Fecha Factura',
            type: 'date',
            renderCell: (cellValues) => {
              return (
                
                  (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
          
                  <Moment format="DD/MM/YYYY">
                    {cellValues.value}
                  </Moment>
          
                  :
          
                  <></>
          
                
              );
            } 
        },
        {
            'field': 'ultima_fecha_abono', 
            'width':200, 
            'headerName': 'F. Abono', 
            'description': 'Fecha Abono',
            type: 'date',
            renderCell: (cellValues) => {
              return (
                
                  (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
          
                  <Moment format="DD/MM/YYYY">
                    {cellValues.value}
                  </Moment>
          
                  :
          
                  <></>
          
                
              );
            } 
        },
        { field: 'created', headerName: 'Creado', maxWidth: 250, flex: 1 },
        { field: 'created_usuario_nombre', headerName: 'Creado por', maxWidth: 250, flex: 1 },
        { field: 'codigo_cobro', headerName: 'Codigo banco', maxWidth: 250, flex: 1 },

        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
              <GridActionsCellItem
                icon={<InsertLinkIcon />}
                label="Crear enlace de pago"
                onClick={() => linkCrearEnlace(params.row)}
                showInMenu
              />,

              <GridActionsCellItem
                icon={<SendIcon />}
                label="Enviar enlace de pago"
                onClick={() => linkEnviar(params.row)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<SendIcon />}
                label="Realizar cobro automatico tarjeta"
                onClick={() => linkCobro(params.row)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<SendIcon />}
                label="Realizar cobro manual"
                onClick={() => linkCobroManual(params.row)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<ReceiptIcon />}
                label="Crear factura"
                onClick={() => linkCrearFactura(params.row)}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<ReceiptIcon />}
                label="Crear abono"
                onClick={() => linkCrearAbono(params.row)}
                showInMenu
              />,
              
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Eliminar"
                onClick={() => linkDelete(params.row)}
                showInMenu
              />,
            ],
        },
    ];

    /* -------------------------------------------------------------------------- */
    /*                                   Eventos                                  */
    /* -------------------------------------------------------------------------- */
    const linkEnviar = (params) => {
        let id = params.id

        if(params.estado_id === "123") {
            Swal.fire('No se puede pasar un cobro 2 veces', '', 'info')
            return null;
        }
        Swal.fire({
            title: '¿Realmente deseas enviar el enlace de pago?',
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                const usuario_id = usuarioSeleccionado.id

                enviar_enlace_pago_alumno_producto_cuota( {
                    usuario_id: usuario_id, 
                    alumno_producto_cuota_id: id
                } ).then((response) => {
                    
                    alumno_producto_id && getAlumnosProductos({ 
                        usuario_id: usuario_id,
                        alumno_producto_id: alumno_producto_id
                    }).then( (response) => {
                        setAlumnoProducto(response.alumno_producto)
                    })

                })

            }
          })
    }
    const linkCrearFactura = (params) => {
        let id = params.id

        if(params.ultima_fecha_factura !== "" && params.ultima_fecha_factura !== "0000-00-00 00:00:00" && params.ultima_fecha_factura !== null){
            Swal.fire('La factura ya está generada', '', 'info')
            return null;
        }
        if(params.estado_id !== "123" && params.estado_id !== "125") {
            Swal.fire('No se puede generar una factura sin cobro', '', 'info')
            return null;
        }
        Swal.fire({
            title: '¿Realmente deseas crear la factura?',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                const usuario_id = usuarioSeleccionado.id

                crear_factura_alumno_producto_cuota( {
                    usuario_id: usuario_id, 
                    alumno_producto_cuota_id: id
                } ).then((response) => {
                    
                    alumno_producto_id && getAlumnosProductos({ 
                        usuario_id: usuario_id,
                        alumno_producto_id: alumno_producto_id
                    }).then( (response) => {
                        setAlumnoProducto(response.alumno_producto)
                    })

                })

            }
          })
    }
    const linkCrearAbono = (params) => {
        let id = params.id

        if(params.ultima_fecha_factura === "" || params.ultima_fecha_factura === "0000-00-00 00:00:00" || params.ultima_fecha_factura === null){
            Swal.fire('No se puede crear el abono si no hay factura', '', 'info')
            return null;
        }
        if(params.ultima_fecha_abono !== "" && params.ultima_fecha_abono !== "0000-00-00 00:00:00" && params.ultima_fecha_abono != null){
            console.log(params.ultima_fecha_abono)
            Swal.fire('No se puede crear el abono si ya hay abono', '', 'info')
            return null;
        }

        Swal.fire({
            title: '¿Realmente deseas crear la factura de abono?',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const usuario_id = usuarioSeleccionado.id

                crear_abono_alumno_producto_cuota( {
                    usuario_id: usuario_id, 
                    alumno_producto_cuota_id: id
                } ).then((response) => {
                    
                    alumno_producto_id && getAlumnosProductos({ 
                        usuario_id: usuario_id,
                        alumno_producto_id: alumno_producto_id
                    }).then( (response) => {
                        setAlumnoProducto(response.alumno_producto)
                    })

                })

            }
          })
    }
    const linkCobro = (params) => {
        let id = params.id

        if(params.ds_merchant_identifier === "") {
            Swal.fire('Para enviar un cobro es necesario que el alumno haya realizado algún pago previo', '', 'info')
            return null;
        }
        if(params.estado_id === "123") {
            Swal.fire('No se puede pasar un cobro 2 veces', '', 'info')
            return null;
        }
        Swal.fire({
            title: '¿Realmente deseas realizar el cobro?',
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                const usuario_id = usuarioSeleccionado.id

                enviar_cobro_alumno_producto_cuota( {
                    usuario_id: usuario_id, 
                    alumno_producto_cuota_id: id
                } ).then((response) => {
                    
                    alumno_producto_id && getAlumnosProductos({ 
                        usuario_id: usuario_id,
                        alumno_producto_id: alumno_producto_id
                    }).then( (response) => {
                        setAlumnoProducto(response.alumno_producto)
                    })

                })

            }
          })
    }
    const linkCrearEnlace = (params) => {
        let id = params.id

        if(params.estado_id === "123") {
            Swal.fire('No se puede pasar un cobro 2 veces', '', 'info')
            return null;
        }
        Swal.fire({
            title: '¿Realmente deseas crear el enlace de pago?',
            showCancelButton: true,
            confirmButtonText: 'Crear',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                const usuario_id = usuarioSeleccionado.id

                crear_enlace_pago( {
                    usuario_id: usuario_id, 
                    alumno_producto_cuota_id: id
                } ).then((response) => {
                    
                    Swal.fire(response.enlace, '', 'info')

                })

            }
          })
    }
    const linkCobroManual = (params) => {
        let id = params.id
        
        if(params.estado_id === "123" || params.estado_id === "125") {
            Swal.fire('No se puede pasar un cobro 2 veces', '', 'info')
            return null;
        }
        Swal.fire({
            title: '¿Realmente deseas realizar el cobro manual?',
            showCancelButton: true,
            confirmButtonText: 'Enviar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                const usuario_id = usuarioSeleccionado.id

                enviar_cobro_manual_alumno_producto_cuota( {
                    usuario_id: usuario_id, 
                    alumno_producto_cuota_id: id
                } ).then((response) => {
                    
                    alumno_producto_id && getAlumnosProductos({ 
                        usuario_id: usuario_id,
                        alumno_producto_id: alumno_producto_id
                    }).then( (response) => {
                        setAlumnoProducto(response.alumno_producto)
                    })

                })

            }
          })
    }
    const linkDelete = (params) => {
        let id = params.id

        if(params.ultima_fecha_factura !== null) {
            Swal.fire('No se puede eliminar una cuota ya facturada', '', 'info')
            return null
        }
        Swal.fire({
            title: '¿Realmente deseas eliminar esta cuota?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: `Cancelar`,
            confirmButtonColor: '#afcb0d',
            cancelButtonColor: '#a5a5a5',
            icon: 'warning',
            reverseButtons: true
          }).then((result) => {

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                
                const usuario_id = usuarioSeleccionado.id

                eliminar_alumno_producto_cuota( {
                    usuario_id: usuario_id, 
                    alumno_producto_cuota_id: id
                } ).then((response) => {
                    
                    alumno_producto_id && getAlumnosProductos({ 
                        usuario_id: usuario_id,
                        alumno_producto_id: alumno_producto_id
                    }).then( (response) => {
                        setAlumnoProducto(response.alumno_producto)
                    })
                    
                })

    //         } else if (result.isDenied) {
    //         //   Swal.fire('Changes are not saved', '', 'info')
            }
          })
    }

    /* -------------------------------------------------------------------------- */
    /*                                   Return                                   */
    /* -------------------------------------------------------------------------- */
    return (
        <>

        { alumno_producto_id === undefined ?
            
            <Box sx={{marginX: 2}}>
                <Paper style={{padding: 20}}>
                    <TituloForm titulo="Añadir producto" />
                    <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={formikProducto.handleSubmit}>
                        <Grid container  spacing={2}>
                            <Grid item xs={4}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginTop: 16 }}
                                    error={formikProducto.touched.producto_id && Boolean(formikProducto.errors.producto_id)}
                                >
                                    <InputLabel id="producto_id-label">Producto</InputLabel>
                                    <Select
                                        labelId="producto_id-label"
                                        id="producto_id"
                                        name="producto_id"
                                        label="Producto"
                                        fullWidth
                                        value={formikProducto.values.producto_id}
                                        onChange={formikProducto.handleChange}
                                        error={formikProducto.touched.producto_id && Boolean(formikProducto.errors.producto_id)}
                                    >
                                        {        
                                            productos?.map( (item) => {
                                                return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                            })
                                        }

                                    </Select>
                                    <FormHelperText>{formikProducto.touched.producto_id && formikProducto.errors.producto_id}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={2} style={{marginTop: 16}}>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        id="fecha_inicio"
                                        label="Fecha inicio"
                                        placeholder='Introduce la fecha de inicio'
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        value={formikProducto.values.fecha_inicio}
                                        onChange={value => formikProducto.setFieldValue("fecha_inicio", value)}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date"
                                        }}
                                        error={formikProducto.touched.fecha_inicio && Boolean(formikProducto.errors.fecha_inicio)}
                                        helperText={formikProducto.touched.fecha_inicio && formikProducto.errors.fecha_inicio}
                                    />
                                </MuiPickersUtilsProvider>

                            </Grid>
                            <Grid item xs={2}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                style={{ marginTop: 16 }}
                                error={formikProducto.touched.forma_pago_id && Boolean(formikProducto.errors.forma_pago_id)}
                            >
                                <InputLabel id="forma_pago_id-label">Forma de pago</InputLabel>
                                <Select
                                    labelId="forma_pago_id-label"
                                    id="forma_pago_id"
                                    name="forma_pago_id"
                                    label="Forma de pago"
                                    fullWidth
                                    value={formikProducto.values.forma_pago_id}
                                    onChange={formikProducto.handleChange}
                                    error={formikProducto.touched.temperatura_id && Boolean(formikProducto.errors.forma_pago_id)}
                                >
                                    
                                    {        
                                        formasPago && formasPago.map( (item) => {
                                            return (<MenuItem key={item.id} value={item.id}>{item.nombre}</MenuItem>)
                                        })
                                    }

                                </Select>
                                <FormHelperText>{formikProducto.touched.temperatura_id && formikProducto.errors.temperatura_id}</FormHelperText>
                            </FormControl>
                        </Grid>
                            <Grid item xs={12} md={2}>

                                <TextField
                                    id="num_cuotas"
                                    name="num_cuotas"
                                    label="Num. cuotas"
                                    placeholder="Introduce el numero de cuotas"
                                    fullWidth
                                    margin="normal"
                                    value={formikProducto.values.num_cuotas}
                                    onChange={formikProducto.handleChange}
                                    variant="outlined"
                                    error={formikProducto.touched.num_cuotas && Boolean(formikProducto.errors.num_cuotas)}
                                    helperText={formikProducto.touched.num_cuotas && formikProducto.errors.num_cuotas}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                            </Grid>
                            
                            <Grid item xs={12} md={2}>

                                <TextField
                                    id="importe_cuota"
                                    name="importe_cuota"
                                    label="Importe de las cuotas"
                                    placeholder="Introduce el importe de las cuotas"
                                    fullWidth
                                    margin="normal"
                                    value={formikProducto.values.importe_cuota}
                                    onChange={formikProducto.handleChange}
                                    variant="outlined"
                                    error={formikProducto.touched.importe_cuota && Boolean(formikProducto.errors.importe_cuota)}
                                    helperText={formikProducto.touched.importe_cuota && formikProducto.errors.importe_cuota}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                            </Grid>

                        </Grid>
                        <Box mt={5}>
                        <Divider />
                        <Box mt={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        
                            <Button color="primary" style={{ marginRight: '30px' }} onClick={ () => setShowFormProducto(false)}>Cancelar</Button>

                            <Button 
                                variant="contained" 
                                color="primary" 
                                type="submit"
                            >
                                Guardar
                            </Button>

                        </Box>
                    </Box>  
                    </form>
                </Paper>
            </Box>

        : 
            <>

            <Box sx={{marginY: 2}}>
                <Paper style={{padding: 20}}>
                    <TituloForm titulo="Añadir cuota" />
                    <form noValidate method="POST" enctype="multipart/form-data" autoComplete="off" onSubmit={formikCuota.handleSubmit}>
                        <Grid container  spacing={2}>
                            <Grid item xs={6} md={2} style={{marginTop: 16}}>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        id="fecha"
                                        label="Fecha"
                                        placeholder='Introduce la fecha'
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        value={formikCuota.values.fecha}
                                        onChange={value => formikCuota.setFieldValue("fecha", value)}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date"
                                        }}
                                        error={formikCuota.touched.fecha && Boolean(formikCuota.errors.fecha)}
                                        helperText={formikCuota.touched.fecha && formikCuota.errors.fecha}
                                    />
                                </MuiPickersUtilsProvider>

                            </Grid>
                            <Grid item xs={12} md={2}>

                                <TextField
                                    id="importe"
                                    name="importe"
                                    label="Importe"
                                    placeholder="Introduce el importe"
                                    fullWidth
                                    margin="normal"
                                    value={formikCuota.values.importe}
                                    onChange={formikCuota.handleChange}
                                    variant="outlined"
                                    error={formikCuota.touched.importe && Boolean(formikCuota.errors.importe)}
                                    helperText={formikCuota.touched.importe && formikCuota.errors.importe}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                            </Grid>
                        </Grid>
                        <Box mt={5}>
                        <Divider />
                        <Box mt={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        
                            <Button color="primary" style={{ marginRight: '30px' }} onClick={ () => setShowFormProducto(false)}>Volver</Button>

                            <Button 
                                variant="contained" 
                                color="primary" 
                                type="submit"
                            >
                                Añadir cuota
                            </Button>

                        </Box>
                    </Box>  
                    </form>
                </Paper>
            </Box>

            
            { 
                loadingCuotas ?
                
                <Box style={{width: '100%', textAlign: 'center'}}>Cargando cuotas...</Box>

                :

                alumnoProducto && 
                <div style={{ height: 500, width: '100%', padding:"" }}>
                    <DataGrid 
                        rows={alumnoProducto.cuotas} 
                        columns={columnas_cuotas} 
                    />
                </div>

            }
            </>
        }

        </>
    )
};

export default AlumnosProductoForm;